<template>
  <div class="toolbar py-2 mb-4" id="kt_toolbar">
    <div id="kt_toolbar_container" class="container-fluid d-flex align-items-center">
      <div class="flex-grow-1 flex-shrink-0 me-5">
        <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
             data-kt-swapper-parent="{default: '#kt_content_container', 'lg': '#kt_toolbar_container'}"
             class="page-title d-flex align-items-center flex-wrap me-3 mb-5 mb-lg-0">

          <h1 class="d-flex align-items-center text-dark fw-bolder my-1 fs-3">{{ this.$route.meta.title }}</h1>

          <span class="h-20px border-gray-200 border-start mx-3"></span>

          <ul class="breadcrumb breadcrumb-separatorless fw-bold fs-7 my-1" v-for="(breadcrumb, index) in breadcrumbList">
            <li class="breadcrumb-item text-muted"
                :key="index" @click="routeTo(index)"
                :class="{'linked': !!breadcrumb.link, 'text-hover-primary': breadcrumb.link}">
              &nbsp {{ breadcrumb.name }} &nbsp
              <span class="bullet w-5px h-2px" v-if="index < breadcrumbList.length-1"></span>
            </li>
          </ul>
        </div>
      </div>

        <slot name="actions"></slot>

    </div>
</div>
</template>

<script>
export default {
name: "Toolbar",
data() {
return {
  breadcrumbList: [],
}
},
props: {
title: String,
},
mounted() {
this.updateList();
},
watch: {
'$route' () {
  this.updateList();
},
},
methods: {
routeTo(route) {
  if (this.breadcrumbList[route].link)
    this.$router.push(this.breadcrumbList[route].link);
},
updateList() {
  this.breadcrumbList = this.$route.meta.breadcrumb;
}
},
}

</script>

<style scoped>

</style>