<template>
  <div class="modal fade" tabindex="-1" id="modal_prof_spec" ref="modal_prof_spec">
    <div class="modal-dialog modal-lg">
      <div class="modal-content modal-lg">
        <div class="modal-header">
          <h5 class="modal-title">Especialidades</h5>
          <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
            <i class="bi bi-x-lg"></i>
          </div>
        </div>
        <div class="modal-body">
          <div class="container">
            <div class="fv-row mb-5">
              <div class="row">
                <div class="col-xl-6">
                  <div class="card card-flush h-xl-100">
                    <div class="card-body">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark">Áreas</span>
                      </h3>
                      <div class="hover-scroll-overlay-y pe-6 me-n6" style="height: 415px">
                        <div class="rounded border-1 border-dashed px-7 py-3 mb-6"
                             :class="selectedKindId === kind.id_kind ? 'border-primary' : 'border-gray-300'"
                             style="cursor: pointer;" v-for="kind in kinds"
                             @click="loadSpecialtiesFromKind(kind.id_kind)">
                          <div class="d-flex flex-stack mb-3">
                            <div class="me-3">
                              {{ kind.kind_name }}
                              <span class="badge badge-light-info">
                              {{ kind.kind_acronym }}
                            </span>
                            </div>
                          </div>

                          <div class="form-check form-switch">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault"
                                   :checked="selectedKindIds.includes(kind.id_kind)"
                                   @click="addRemoveKind(kind.id_kind)">
                          </div>
                          <div class="d-flex flex-stack" v-if="selectedKindIds.includes(kind.id_kind)">
                            <label class="form-label fw-bolder text-dark fs-6 mt-4">{{
                                kind.kind_acronym + ':'
                              }}</label>
                            <input type="text" class="form form-control mt-2 ms-4"
                                   v-model="kind.prof_council">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6">
                  <div class="card card-flush h-xl-100">
                    <div class="card-body">
                      <h3 class="card-title align-items-start flex-column">
                        <span class="card-label fw-bolder text-dark">Especialidades</span>
                        <input type="text" v-model="searchSpec"
                               class="form-control form-control-sm form-control-solid mt-5 mb-5"
                               placeholder="Pesquisar ...">
                      </h3>
                      <div class="hover-scroll-overlay-y pe-6 me-n6" id="specialties"
                           style="height: 415px">
                        <div class="rounded border-gray-300 border-1 border-gray-300 border-dashed px-7 py-3 mb-6"
                             style="cursor: pointer;" v-for="specialty in filteredSpecialties"
                             @click="selectSpecialty(specialty.id_spec)">
                          <div class="d-flex flex-stack mb-3">
                            <div class="me-3">
                              {{ specialty.spec_name }}
                            </div>
                          </div>
                          <span class="text-gray-400 fw-bolder"></span>
                          <div class="d-flex flex-stack" v-if="selectedSpecialties.includes(specialty.id_spec)">
                          <span class="badge badge-light-success">
                            Selecionado
                          </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
          <button type="button" class="btn btn-primary" @click="saveSpecialtiesFromProf()">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Input from "../attendance/shared/Input";

export default {
  name: "ModalProfSpec",
  components: {Input},
    props: ['professional'],
  data() {
    return {
      searchSpec: '',
      kinds: [],
      selectedKindIds: [],
      selectedKindId: '',
      specialties: [],
      selectedSpecialties: [],
      profCouncil: '',
    }
  },
  mounted() {
    const modalElement = document.getElementById('modal_prof_spec');
    modalElement.addEventListener('shown.bs.modal', async () => {
      await Promise.all([this.loadKinds(), this.loadkindsFromProf()]);
      this.loadSpecialiesFromProf();
    });
    modalElement.addEventListener('hidden.bs.modal', () => {
      this.searchSpec = '';
      this.kinds = [];
      this.selectedKindIds = [];
      this.selectedKindId = '';
      this.specialties = [];
      this.selectedSpecialties = [];
      this.profCouncil = '';
    });
  },
  computed: {
    filteredSpecialties() {
      return this.specialties?.filter(specialty => {
        return specialty.spec_name.toLowerCase().includes(this.searchSpec.toLowerCase())
      })
    },
  },
  methods: {
    selectSpecialty(specialtyId) {
      let index = this.selectedSpecialties.indexOf(specialtyId);
      if (index === -1) {
        this.selectedSpecialties.push(specialtyId);
      } else {
        this.selectedSpecialties.splice(index, 1);
      }
    },
    loadSpecialtiesFromKind(kindId) {
      if (this.selectedKindIds.includes(kindId)) {
        http.get(`kinds/${kindId}/specialties`).then((response) => {
          this.specialties = response.data;
        }).catch((e) => {
          Alert.toastError('Erro ao buscar especialidades!')
        });
      } else {
        this.specialties = [];
      }
      this.selectedKindId = kindId;
    },
    assignCouncil() {
      http.get(`professionals/${this.professional.id_prof}/kinds`).then((response) => {
        let profKindsMap = {};
        response.data.forEach(profKind => {
          profKindsMap[profKind.id_kind] = profKind.prof_council;
        });
        this.kinds.forEach(kind => {
          kind.prof_council = profKindsMap[kind.id_kind] !== undefined ? profKindsMap[kind.id_kind] : null;
        });
      }).catch((e) => {
        Alert.toastError('Erro ao buscar áreas!')
      });
    },
    loadKinds() {
      return http.get(`kinds`).then((response) => {
        this.kinds = response.data;
        this.assignCouncil();
      }).catch((e) => {
        Alert.toastError('Erro ao buscar áreas!')
      });
    },
    loadkindsFromProf() {
      return http.get(`professionals/${this.professional.id_prof}/kinds`).then((response) => {
        this.selectedKindIds = response.data.map(function (a) {
          return a.id_kind;
        });
        if (this.selectedKindIds.length > 0) {
          this.loadSpecialtiesFromKind(this.selectedKindIds[0]);
        }
      }).catch((e) => {
        Alert.toastError('Erro ao buscar tipos!')
      });
    },
    loadSpecialiesFromProf() {
      return http.get(`professionals/${this.professional.id_prof}/specialties`).then((response) => {
        this.selectedSpecialties = response.data.map(function (a) {
          return a.id_spec;
        });
      }).catch((e) => {
        Alert.toastError('Erro ao buscar especialidades do profissional!')
      });
    },
    saveSpecialtiesFromProf() {
      let kindsData = this.kinds.filter(kind => this.selectedKindIds.includes(kind.id_kind))
          .map(({id_kind, prof_council}) => ({id_kind, prof_council}));
      if (kindsData.some(k => !k.prof_council)) {
        Alert.toastError('O número do conselho profissional é obrigatório!')
        return;
      }
      http.put(`professionals/${this.professional.id_prof}/specialties`, {
        specialties: this.selectedSpecialties,
        kinds: kindsData
      }).then((response) => {
        Alert.toastSuccess('Gravação realizada com sucesso!')
        bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_prof_spec')).toggle();
      }).catch((e) => {
        Alert.toastError('Erro ao gravar especialidades!')
      });
    },
    addRemoveKind(kindId) {
      let index = this.selectedKindIds.indexOf(kindId);
      if (index === -1) {
        this.selectedKindIds.push(kindId);
      } else {
        this.selectedKindIds.splice(index, 1);
      }
      this.loadSpecialtiesFromKind(kindId);
    },
  }
}
</script>

<style scoped>

</style>