<template>
  <div class="table-responsive">
    <table class="table table-row-bordered gy-5">
      <thead>
      <tr class="fw-bold fs-6 text-muted">
        <th>E-mail</th>
        <th>Tipo de Acesso</th>
        <th>Status</th>
        <th>Ações</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="invite in invitesFiltered">
        <td>{{invite.invite_email}}</td>
        <td>{{invite.access_type.access_type_name}}</td>
        <td>
          <span class="badge badge-info" v-if="invite.invite_status === 'S'">Enviado</span>
          <span class="badge badge-danger" v-if="invite.invite_status === 'C'">Cancelado</span>
          <span class="badge badge-success" v-if="invite.invite_status === 'A'">Aceito</span>
          <span class="badge badge-warning" v-if="invite.invite_status === 'R'">Recusado</span>
        </td>
        <td>
          <div class="btn-group" v-if="invite.invite_status === 'S'">
            <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                    @click="resendInvite(invite.id_invite)"
                    data-bs-toggle="tooltip" title="Reenviar usuário">
              <i class="bi bi-send-fill"></i>
            </button>
            <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                    @click="cancelInvite(invite.id_invite)"
                    data-bs-toggle="tooltip" title="Cancelar usuário">
              <i class="bi bi-x-octagon"></i>
            </button>
          </div>
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="modal fade" tabindex="-1" id="modal_store">
    <div class="modal-dialog modal-lg">
      <form method="post" @submit.prevent="submitFormStore">
        <div class="modal-content modal-lg">
          <div class="modal-header">
            <h5 class="modal-title">Adicionar usuário</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
          <div class="modal-body">
            <div class="row">

              <div class="col-md-12">
                <div class="mb-6">
                  <div class="mt-4">
                    <label for="id_access_type_store" class="required form-label">Tipo de Acesso</label>
                    <select class="form-control  form-control-solid" id="id_access_type_store"
                            aria-label="Selecione o convite" v-model="formStore.id_access_type" required>
                      <option value="">--Selecione--</option>
                      <option :value="accessType.id_access_type" v-for="accessType in accessTypes">
                        {{ accessType.access_type_name }}
                      </option>
                    </select>
                    <small class="text-danger" v-if="formStoreErrors.id_access_type">
                      {{ formStoreErrors.id_access_type.join('\n') }}
                    </small>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="form-group col-md-6">
                  <label for="add_user_email">E-mail(s) <span class="text-danger">*</span></label>
                  <div class="input-group mb-3">
                    <input type="email" class="form-control" id="add_user_email"
                           v-model="email" maxlength="100">
                    <div class="input-group-append">
                      <button class="btn btn-md btn-primary ms-2" type="button"
                              title="Adicionar e-mail" @click="addEmail">
                        <i class="bi bi-plus"></i>
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-12" v-for="(email, index) in formStore.emails">
                      <div class="alert alert-primary alert-dismissible fade show" style="width: fit-content">
                        {{ email }}
                        <button type="button" class="btn-close" @click="removeEmail(index)"
                                aria-label="Close">
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <small class="text-danger" v-if="formStoreErrors.emails">
                  {{ formStoreErrors.emails.join('\n') }}
                </small>
              </div>

            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">Adicionar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";

export default {
  name: "InviteUser",
  props: {
    searchParam: String,
  },
  data() {
    return {
      invites: [],
      accessTypes: [],
      searchInvite: '',
      email: '',
      formStore: {
        emails: [],
        id_access_type: '',
      },
      formStoreErrors: [],
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    invitesFiltered() {
      if (!this.searchParam)
        return this.invites;

      return this.invites.filter(invite => {
        return invite.invite_email.toLowerCase().includes(this.searchParam.toLowerCase())
      });
    },
  },
  mounted() {
    this.loadAccessTypes();
    this.loadInvites();
  },
  methods: {
    removeEmail(index) {
      this.formStore.emails.splice(index, 1);
    },
    addEmail() {
      let emails = this.email;
      if (!emails)
        return;

      emails.split(',').forEach((email) => {
        if (this.formStore.emails.indexOf(email) === -1) {
          this.formStore.emails.push(email);
        }

        this.email = null;
      });
    },
    submitFormStore() {
      http.post(`/places/${this.place.id_place}/users/invites`, this.formStore).then(_ => {
        this.$emit('onSubmit');
        this.loadInvites();

        Alert.toastSuccess("Convite enviado com sucesso para os e-mails.");
        this.formStoreErrors = [];
        this.formStore = {
          emails: [],
          id_access_type: '',
        };

        bootstrap.Modal.getInstance(document.getElementById('modal_store')).toggle();
      }).catch((e) => {
        console.log(e);
        Alert.toastError(e.response.data.message ?? e.response.data);
        if (e.response.data.errors) {
          this.formStoreErrors = e.response.data.errors;
        }
      });
    },
    loadAccessTypes() {
      let params = {params: {'access_type_level': 'EM'}};
      http.get(`/places/${this.place.id_place}/access-types/for-invite`, params).then((response) => {
        this.accessTypes = response.data;
      });
    },
    loadInvites() {
      let params = {params: {'access_type_level': 'EM'}};
      http.get(`/places/${this.place.id_place}/users/invites`, params).then((response) => {
        this.invites = response.data;
      });
    },
    cancelInvite(inviteId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja cancelar o convite?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, cancelar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.patch(`/places/${this.place.id_place}/users/invites/${inviteId}/cancel`).then(_ => {
            this.loadInvites();
            Alert.toastSuccess("Convite cancelado com sucesso.");
          }).catch(e => {
            Alert.toastError(e.response.data.message??e.response.data);
          })
        }
      });
    },
    resendInvite(inviteId) {
      http.post(`/places/${this.place.id_place}/users/invites/${inviteId}/resend`).then(_ => {
        Alert.toastSuccess("Convite reenviado com sucesso.");
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
      });
    }
  }
}
</script>

<style scoped>

</style>