<template>
  <div>
    <div class="card">
      <div class="card-header border-0 pt-5">
        <div class="card-title align-items-start flex-column">
            <div class="card-label fw-bolder text-dark">Recados</div>
            <div class="form-check form-switch mt-3">
                <label class="form-check-label" for="include_trashed">Excluídos?</label>
                <input class="form-check-input" type="checkbox" role="switch" v-model="includeTrashed" id="include_trashed"
                       @change="loadNotes()">
            </div>
        </div>
          <div class="card-toolbar">
              <div class="col-md-12">
                  <button v-if="hasPermission('Store Note')" class="btn btn-sm btn-primary mb-4 mt-5" @click="inserting = !inserting">+ Adicionar</button>
              </div>
          </div>
      </div>
      <div class="card-body pt-0">
        <PostIt v-if="inserting"
                key="new"
                title="Novo Recado"
                :content="newNote"
                :readonly="false"
                :canDelete="true"
                @onStoreClick="insertNote"
                @onDeleteClick="inserting = false"
        />
        <PostIt v-for="note in notes"
                :key="note.id_note"
                :title="note.user_name +  '-' + date(note.created_at).format('DD/MM/YYYY')"
                :content="note.note"
                :readonly="true"
                :canDelete="hasPermission('Delete Note')"
                :deleted="note.deleted_at != null"
                @onDeleteClick="deleteNote(note.id_note)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../helpers/http";
import PostIt from "../../components/PostIt.vue";
import Alert from "../../helpers/alert";
import Input from "../attendance/shared/Input.vue";
import Permission from "../../helpers/permission";

export default {
  name: "Notes",
  props: ['profId', 'specId'],
  components: {
    Input,
    PostIt,
  },
  data() {
    return {
      notes: [],
      newNote: '',
      inserting: false,
      includeTrashed: false,
    }
  },
  watch: {
    profId() {
      this.loadNotes();
    },
    specId() {
      this.loadNotes();
    },
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
  },
  mounted() {
    this.loadNotes();
  },
  methods: {
    date: moment,
    hasPermission(permission) {
      return Permission.has(permission);
    },
    loadNotes() {
      if (!this.profId) {
        return;
      }
      return http.get(`/places/${this.place.id_place}/professionals/${this.profId}/notes`, {
        params: {
          include_trashed: this.includeTrashed,
        }
      }).then((response) => {
        this.notes = response.data;
      });
    },
    deleteNote(id) {
      return http.delete(`/places/${this.place.id_place}/professionals/${this.profId}/notes/${id}`).then((response) => {
        Alert.toastSuccess('Recado excluído com sucesso!')
        this.loadNotes();
      }).catch((error) => {
        Alert.toastError('Erro ao excluir recado - ', error.response.data.message);
      });
    },
    insertNote(note) {
      return http.post(`/places/${this.place.id_place}/professionals/${this.profId}/notes`, {
        'note': note
      }).then((response) => {
        Alert.toastSuccess('Recado incluído com sucesso!')
        this.inserting = false;
        this.loadNotes();
      }).catch((error) => {
        Alert.toastError('Erro ao incluir recado - ', error.response.data.message);
      });
    },
  },
}

</script>

<style scoped>

</style>