<template>
  <div class="modal fade" id="kt_modal_event" data-bs-keyboard="false"
       data-bs-backdrop="static" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">

        <div class="modal-header">
          <h2 class="fw-bolder" data-kt-calendar="title">
            {{ scheduleEdit ? '' : 'Novo' }} Agendamento
            <span class="badge badge-light-success" v-if="scheduleEdit?.sched_online">
                Atendimento Online
            </span>

            <span class="flex-grow-1 me-2 text-center">
                <span v-if="scheduleEdit?.is_sched_finished" class="badge badge-success fw-bolder mx-2">
                    Consulta Finalizada {{ scheduleEdit?.finished_at_br }}
                </span>
                <span
                    v-else-if="scheduleEdit?.is_sched_in_progress"
                    class="badge badge-light-success fw-bolder mx-2">
                    Consulta em Andamento
                </span>
                <span v-else-if="scheduleEdit?.is_sched_confirmed"
                      class="badge badge-light-primary fw-bolder mx-2">
                    Consulta confirmada {{ scheduleEdit?.confirmed_at_br }}
                </span>
                <span v-else-if="scheduleEdit?.is_sched_canceled"
                      class="badge badge-danger fw-bolder mx-2">
                    Consulta cancelada {{ scheduleEdit?.canceled_at_br }}
                </span>
                <span v-else-if="scheduleEdit?.is_return"
                      class="badge badge-warning fw-bolder mx-2">
                    Retorno
                </span>
            </span>
          </h2>
          <div class="btn btn-icon btn-sm btn-active-icon-primary"
               @click="$emit('onCloseModal')"
               data-bs-dismiss="modal" aria-label="Fechar">
              <span class="svg-icon svg-icon-1">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                       fill="none">
                    <rect opacity="0.5" x="6" y="17.3137" width="16" height="2" rx="1"
                          transform="rotate(-45 6 17.3137)"
                          fill="black"/>
                    <rect x="7.41422" y="6" width="16" height="2" rx="1" transform="rotate(45 7.41422 6)"
                          fill="black"/>
                  </svg>
                </span>
          </div>
        </div>
        <div class="modal-body pt-1">
          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <button class="nav-link active" id="data-schedule-modal-tab"
                      data-bs-toggle="tab" data-bs-target="#data-schedule-modal"
                      type="button" role="tab" aria-controls="home" aria-selected="true">
                DADOS
              </button>
            </li>
            <li class="nav-item" v-show="scheduleEdit != null && showHistory">
              <button class="nav-link" id="data-history-modal-tab"
                      data-bs-toggle="tab" data-bs-target="#data-history-modal"
                      type="button" role="tab" aria-controls="history" aria-selected="false">
                  HISTÓRICO DE AGENDAMENTOS
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active"
                 id="data-schedule-modal" role="tabpanel"
                 aria-labelledby="home-tab">

              <br>
              <form class="form" @submit.prevent="onSubmit">
                <div class="row py-5">

                  <div class="col-md-5">
                    <div class="fv-row">
                      <label class="fs-6 required fw-bold mb-2" for="id_prof">Profissional</label>
                      <Dropdown :options="professionals"
                                :filter="true"
                                appendTo="self"
                                :disabled="scheduleEdit != null"
                                @change="onChangeProf($event.value);"
                                v-model="form.id_prof"
                                optionLabel="prof_name"
                                optionValue="id_prof"
                                empty-message="Nenhuma opção"
                                empty-filter-message="Nenhuma opção encontrada"
                                id="id_prof"
                                class="form-control form-control-solid w-full md:w-14rem"
                                placeholder="Profissional"
                                style="display: flex;"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="fv-row">
                      <label class="fs-6 required fw-bold mb-2">Especialidade</label>
                      <Dropdown :options="specialties"
                                :filter="true"
                                appendTo="self"
                                v-model="form.id_spec"
                                optionLabel="spec_name"
                                optionValue="id_spec"
                                @change="loadDatesAvailabilityToSchedule(this.form.sched_date)"
                                empty-message="Nenhuma opção"
                                empty-filter-message="Nenhuma opção encontrada"
                                id="id_spec"
                                class="form-control form-control-solid w-full md:w-14rem"
                                placeholder="Especialidades"
                                style="display: flex;"
                      />
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="fv-row">
                      <label class="fs-6 fw-bold mb-2">Forma de recebimento</label>
                      <Dropdown :options="paymentMethods"
                                v-model="form.id_paymeth"
                                optionLabel="paymeth_name"
                                optionValue="id_paymeth"
                                appendTo="self"
                                empty-message="Nenhuma opção"
                                @change="loadDatesAvailabilityToSchedule(this.form.sched_date)"
                                empty-filter-message="Nenhuma opção encontrada"
                                id="id_paymeth"
                                class="form-control form-control-solid w-full md:w-14rem"
                                placeholder="Forma de recebimento"
                                style="display: flex;"
                      />
                    </div>
                  </div>

                  <div class="col-md-5">
                    <div class="fv-row">
                      <label class="fs-6 fw-bold required mb-2">Paciente</label>
                      <SearchPatient v-model="form.sched_patient_name"
                                     :value="form.sched_patient_name"
                                     :disabled="scheduleEdit != null"
                                     :professionalId="parseInt(form.id_prof)"
                                     :key="form.id_prof + '-' + uniqueKey"
                                     @onInput="onClickSearchedPatient"
                                     @onClick="onClickSearchedPatient"
                      />
                    </div>
                  </div>

                  <div class="col-md-4">
                    <div class="fv-row">
                      <label class="fs-6 required fw-bold mb-2">CPF</label>
                      <input type="text" class="form-control form-control-solid"
                             v-mask="'###.###.###-##'" required
                             placeholder="999.999.999-99" v-model="form.sched_patient_cpf"/>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="fv-row">
                      <label class="fs-6 required fw-bold mb-2">Data de nasc.</label>
                      <input type="text" class="form-control form-control-solid"
                             v-mask="'##/##/####'" required
                             placeholder="DD/MM/YYYY" v-model="form.sched_patient_birthdate"/>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <div class="fv-row">
                      <label class="fs-6 required fw-bold mb-2">Telefone</label>
                      <input type="text" class="form-control form-control-solid"
                             v-mask="['(##) ####-####', '(##) #####-####']" required
                             placeholder="(99) 9 9999-9999" v-model="form.sched_patient_phone"/>
                    </div>
                  </div>

                  <div class="col-md-6"  v-show="allowInsurance()">
                    <div class="fv-row">
                      <label class="fs-6 fw-bold mb-2">Convênio</label>
                      <Dropdown :options="insurances"
                                :filter="true"
                                appendTo="self"
                                v-model="form.id_insurance"
                                optionLabel="insurance_name"
                                optionValue="id_insurance"
                                :filter-fields="['insurance_name', 'trading_name']"
                                empty-message="Nenhuma opção"
                                empty-filter-message="Nenhuma opção encontrada"
                                id="id_insurance"
                                class="form-control form-control-solid w-full md:w-14rem form-control-sm"
                                placeholder="Convênio"
                                style="display: flex;"
                      >
                          <template #option="slotProps">
                              <div class="flex align-items-center">
                                  <div v-if="slotProps.option.trading_name">
                                      {{ slotProps.option.trading_name }}
                                      -
                                      {{ slotProps.option.insurance_name }}
                                  </div>
                                  <div v-else>
                                      {{ slotProps.option.insurance_name }}
                                  </div>
                              </div>
                          </template>
                      </Dropdown>
                    </div>
                  </div>
                    <div class="col-md-3">
                        <div class="fv-row">
                            <label class="fs-6 fw-bold mb-2">Número da guia</label>
                            <input type="text" class="form-control form-control-solid"
                                   maxlength="100"
                                   placeholder="Ex. 1234657" v-model="form.sched_guide_number"/>
                        </div>
                    </div>

                  <div class="col-md-12">
                    <label class="fs-6 fw-bold mb-2">Observação</label>
                    <textarea class="form-control form-control-solid"
                              v-model="form.sched_obs"
                              placeholder="Observação da consulta"/>
                  </div>
                </div>

                <div class="row"
                     v-if="scheduleEdit != null && isScheduleDateMoreThanToday() && ['FI', 'CA', 'CM'].includes(scheduleEdit?.sched_status) === false">
                  <div class="col-md-12">
                    <BtnArriveSchedule
                        @onArriveSchedule="arriveSchedule"
                        :scheduleEdit="scheduleEdit"
                        :btnText="scheduleEdit?.sched_arrive ? 'Estornar chegada' : 'Marcar chegada'"
                    />
                    <button
                        v-if="!scheduleEdit?.is_sched_canceled && !scheduleEdit?.sched_arrive && !scheduleEdit?.sched_start"
                        class="btn btn-danger btn-flex me-2 mb-2" type="button"
                        @click="confirmCancel">
                      Cancelar consulta
                    </button>
                    <button type="button"
                            v-if="['AG', 'CO'].includes(scheduleEdit?.sched_status) && !scheduleEdit?.sched_arrive"
                            @click="confirmSchedule(scheduleEdit?.sched_status)"
                            class="btn btn-primary btn-flex me-2 mb-2">
                      {{
                        scheduleEdit?.is_sched_confirmed ? 'Estornar confirmação' : 'Confirmar consulta'
                      }}
                    </button>
                    <BtnFinishOrReOpenSchedule
                        :scheduleEdit="scheduleEdit"
                        :classes="(scheduleEdit?.is_sched_finished ? 'btn-outline btn-outline-info' : 'btn-info')"
                        :btnText="(scheduleEdit?.is_sched_finished ? 'Reabrir atendimento' : 'Finalizar atendimento')"
                        @onFinishSchedule="onFinishSchedule"
                        @onReopenSchedule="onReopenSchedule"
                    />
                    <BtnStartAttendanceSchedule
                        :scheduleEdit="scheduleEdit"
                        :btnText="scheduleEdit.sched_start ? 'Continuar atendimento' : 'Iniciar atendimento'"
                        @onStartAttendance="onStartAttendance"
                    />
                  </div>
                </div>

                <br>
                <div class="row py-5">
                  <div class="col-md-8">
                    <div class="flex flex-wrap gap-3 p-fluid">
                      <div class="flex-auto">
                        <label class="fs-6 fw-bold mb-2 required">Data</label>
                        <Calendar v-model="form.sched_date" dateFormat="yy/mm/dd" inline
                                  :disabled="!isScheduleDateMoreThanToday()"
                                  :manualInput="false"
                                  @dateSelect="onSelectDate"
                                  @monthChange="onChangeMonth">
                          <template #date="slotProps">
                            <div v-if="dateIsAvailabilityToSchedule(slotProps.date)">
                              <div
                                  :style="{ color: getTextColor(slotProps.date), backgroundColor: getBackgoundColor(slotProps.date), padding: '1px', borderRadius: '50%', width: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center'}"
                                  :title="`${getEventRate(slotProps.date)}%`">
                                <strong>{{ slotProps.date.day }}</strong>
                              </div>
                            </div>
                            <div v-else>
                              <i style="pointer-events: none; cursor: not-allowed">
                                {{ slotProps.date.day }}
                              </i>
                            </div>
                          </template>
                        </Calendar>

                        <div class="col-md-6">
                          <div class="d-flex justify-content-around pt-4">
                            <BusyRateLegend/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <label class="fs-6 fw-bold mb-2 required">Hora</label>
                    <input type="text" class="form-control form-control-solid"
                           v-mask="'##:##'" required v-if="form.sched_is_fitted"
                           placeholder="HH:MM" v-model="form.sched_time"/>

                    <select name="" id="" required v-model="form.sched_time" v-else
                            :disabled="!isScheduleDateMoreThanToday()"
                            class="form-control form-control-solid" size="10">

                      <option :value="scheduleEdit.sched_time"
                              v-if="scheduleEdit != null">
                        {{ scheduleEdit.sched_time }}
                      </option>
                      <option :key="time.available_time" :value="time.available_time"
                              v-for="time in timesAvailabilityToSchedule">
                        {{ time.available_time }}
                      </option>
                    </select>

                    <br>
                    <div class="form-check" v-if="isScheduleDateMoreThanToday() && hasPermission('Fit Into Schedule')">
                      <input class="form-check-input" type="checkbox" v-model="form.sched_is_fitted"
                             value="" id="isScheduleFit" @change="form.sched_time = ''">
                      <label class="form-check-label" for="isScheduleFit">
                        Encaixar
                      </label>
                    </div>
                  </div>
                </div>

                <div class="modal-footer flex-center" v-if="isScheduleDateMoreThanToday()">
                  <button type="submit" class="btn btn-primary" :disabled="submiting"
                          v-if="hasPermission(scheduleEdit ? 'Update Schedule' : 'Store Schedule')">
                    <span class="indicator-label">{{ scheduleEdit ? 'Atualizar' : 'Salvar' }}</span>
                    <span class="indicator-progress">Por favor aguarde...
                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  </button>
                </div>
              </form>

            </div>
            <div class="tab-pane fade" id="data-history-modal" role="tabpanel"
                 aria-labelledby="profile-tab" v-show="scheduleEdit != null">

              <PatientScheduleTable v-if="scheduleEdit != null && showHistory"
                                    :uniqueKey="this.uniqueKey + '|'+ this.scheduleEdit.id_patient"
                                    :patientId="this.scheduleEdit.id_patient"
                                    :showBtnEdit="false"/>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Permission from "../../helpers/permission";
import {mask} from "vue-the-mask";
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import SearchPatient from "../../components/SearchPatient";
import PatientScheduleTable from "../patient/PatientScheduleTable";
import BusyRateLegend from "./BusyRateLegend";
import BtnFinishOrReOpenSchedule from "./BtnFinishOrReOpenSchedule";
import BtnStartAttendanceSchedule from "./BtnStartAttendanceSchedule";
import BtnArriveSchedule from "./BtnArriveSchedule";

export default {
  name: "CalendarModalScheduleEdit",
  props: ['scheduleEdit', 'openModal', 'showHistory', 'clickedDate', 'currentProf', 'currentSpec'],
  emits: ['onCloseModal', 'onArriveSchedule', 'onScheduleUpdate', 'onScheduleStore', 'onScheduleCancel', 'onConfirmSchedule', 'onReopenSchedule', 'onFinishSchedule', 'onStartAttendance'],
  components: {
    BusyRateLegend,
    SearchPatient,
    Dropdown,
    Calendar,
    PatientScheduleTable,
    BtnFinishOrReOpenSchedule,
    BtnStartAttendanceSchedule,
    BtnArriveSchedule,
  },
  directives: {
    mask
  },
  data() {
    return {
      specialties: [],
      paymentMethods: [],
      insurances: [],
      datesAvailabilityToSchedule: [],
      timesAvailabilityToSchedule: [],
      uniqueKey: Date.now(),
      form: {
        sched_date: '',
        sched_time: '',
        sched_by: 'PL',
        sched_patient_name: '',
        sched_patient_cpf: '',
        sched_patient_phone: '',
        sched_patient_birthdate: '',
        sched_obs: '',
        id_avail: '',
        id_insurance: '',
        id_paymeth: '',
        id_prof: '',
        id_spec: '',
        id_sched: null,
        id_place: '',
        sched_guide_number: '',
        sched_is_fitted: false,
      },
      submiting: false,
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    professionals() {
      return this.$store.state.auth.professionals;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
    watch: {
    scheduleEdit(newValue, _) {
      if (newValue == null) {
          this.resetForm();
          return;
      }

      this.populateForm()
          .then(() => {
              this.loadDatesAvailabilityToSchedule(this.form.sched_date)
                  .then(() => {
                      this.loadTimesAvailabilityToSchedule(this.form.sched_date);
                  });
      });
    },
    openModal(newValue) {
      if (newValue) {
        document.getElementById('data-schedule-modal-tab').click();
        this.form.id_prof = this.currentProf ?? this.userAuth.id_prof ?? '';
        this.form.id_spec = this.currentSpec ?? '';
        this.onChangeProf(this.form.id_prof);
        this.toggleModal();

        return;
      }

      this.resetForm();
    },
      clickedDate(newValue) {
        if (newValue) {
          let date = this.date(newValue);

          this.form.sched_date = newValue;
          this.form.sched_time = date.format('HH:mm');
        }
      }
  },
  methods: {
    date: moment,
    hasPermission(permission) {
      return Permission.has(permission);
    },
    onSelectDate(selectedDate) {
      this.form.sched_date = selectedDate;
      this.form.sched_time = '';

      this.loadTimesAvailabilityToSchedule(selectedDate);
    },
    onChangeMonth(newDate) {
      const formattedDate = `${newDate.year}-${(newDate.month).toString().padStart(2, '0')}-01`;
      this.loadDatesAvailabilityToSchedule(formattedDate);
    },
    onChangeProf(idProf) {
        if (!idProf)
            return;

        this.loadSpecialties(idProf);
        this.loadPaymentMethods(idProf);
        this.loadInsurances(idProf);
    },
    loadDatesAvailabilityToSchedule(startDate) {
      if (!this.place.id_place || !this.form.id_prof ||
          !this.form.id_spec || !this.place.id_city ||
          !this.form.id_paymeth) {
        return new Promise(resolve => {});
      }

      return http.get('/schedule/availabilities', {
        params: {
          id_place: this.place.id_place,
          id_prof: this.form.id_prof,
          id_spec: this.form.id_spec,
          id_city: this.place.id_city,
          id_paymeth: this.form.id_paymeth,
          id_insurance: this.form.id_insurance,
          start_date: startDate,
          start_end_days: 32,
        }
      }).then((response) => {
        this.datesAvailabilityToSchedule = response.data;
        if (this.clickedDate) {
          let date = this.date(this.clickedDate);
          this.loadTimesAvailabilityToSchedule(date.format('YYYY/MM/DD'));
        }
      });
    },
    loadTimesAvailabilityToSchedule(date) {
      if (!this.place.id_place || !this.form.id_prof ||
          !this.form.id_spec || !this.place.id_city ||
          !this.form.id_paymeth) {
        return;
      }

      http.get('/schedule/availabilities/times', {
        params: {
          id_place: this.place.id_place,
          id_prof: this.form.id_prof,
          id_spec: this.form.id_spec,
          id_city: this.place.id_city,
          id_paymeth: this.form.id_paymeth,
          id_insurance: this.form.id_insurance,
          available_date: date,
        }
      }).then((response) => {
        this.timesAvailabilityToSchedule = response.data;
        if (this.clickedDate) {
          let date = this.date(this.clickedDate);
          const existTime = this.timesAvailabilityToSchedule.some(obj => obj.available_time == date.format('HH:mm'));

          this.form.sched_is_fitted = !existTime;
        }
      });
    },
    dateIsAvailabilityToSchedule(date) {
      const searchDate = this.date(date).format('YYYY-MM-DD');
      return this.datesAvailabilityToSchedule.some(event => event.available_date === searchDate);
    },
    getTextColor(date) {
      const searchDate = this.date(date).format('YYYY-MM-DD');
      const event = this.datesAvailabilityToSchedule.find(event => event.available_date === searchDate);
      return event?.text_color;
    },
    getEventRate(date) {
      const searchDate = this.date(date).format('YYYY-MM-DD');
      const event = this.datesAvailabilityToSchedule.find(event => event.available_date === searchDate);
      return event?.busy_rate;
    },
    getBackgoundColor(date) {
      const searchDate = this.date(date).format('YYYY-MM-DD');
      const event = this.datesAvailabilityToSchedule.find(event => event.available_date === searchDate);

      return event?.background_color;
    },
    confirmCancel() {
      Swal.fire({
        title: "Quem cancelou a consulta?",
        showDenyButton: true,
        showCloseButton: true,
        showCancelButton: false,
        confirmButtonText: "Profissional",
        denyButtonText: `Paciente`,
        confirmButtonColor: "#780f5a",
        denyButtonColor: "rgba(120,15,90,0.8)",
      }).then((result) => {
        if (result.isConfirmed) {
          this.cancelSchedule('PROFESSIONAL');
        } else if (result.isDenied) {
          this.cancelSchedule('PATIENT');
        }
      });
    },
    isScheduleDateMoreThanToday() {
      let dataMoment = moment.utc(this.scheduleEdit?.sched_date).startOf('day');
      let dataAtual = moment.utc().startOf('day');
      return dataAtual.isSameOrBefore(dataMoment, 'day');
    },
    arriveSchedule() {
      this.$emit('onCloseModal');
      this.$emit('onArriveSchedule', this.scheduleEdit.id_sched);
      this.toggleModal();
    },
    populateForm() {
      if (!this.scheduleEdit)
        return new Promise(resolve => {});

      let resSpecs = this.loadSpecialties(this.scheduleEdit.id_prof);
      let resPayment = this.loadPaymentMethods(this.scheduleEdit.id_prof);
      let resInsurance = this.loadInsurances(this.scheduleEdit.id_prof);

      return Promise.all([resPayment, resInsurance, resSpecs]).then(() => {
        let schedDate = new Date(moment.utc(this.scheduleEdit.sched_date).startOf('day').format('YYYY-MM-DDTHH:mm:ss'));
        this.form = {
          id_spec: this.scheduleEdit.id_spec,
          id_prof: this.scheduleEdit.id_prof,
          id_paymeth: this.scheduleEdit.id_paymeth,
          id_avail: this.scheduleEdit.id_avail,
          sched_time: this.scheduleEdit.sched_time,
          sched_patient_name: this.scheduleEdit.patient_name,
          sched_patient_cpf: this.scheduleEdit.patient_cpf,
          sched_patient_phone: this.scheduleEdit.patient_phone,
          sched_patient_birthdate: this.scheduleEdit.patient_birthdate_br,
          sched_guide_number: this.scheduleEdit.sched_guide_number,
          sched_obs: this.scheduleEdit.sched_obs,
          id_insurance: this.scheduleEdit.id_insurance,
          id_sched: this.scheduleEdit.id_sched,
          sched_date: schedDate,
        };

        let paymentMethod = this.paymentMethods.find(p => p.id_paymeth === this.scheduleEdit.id_paymeth);
        if (!paymentMethod) {
          this.paymentMethods.push({
            id_paymeth: this.scheduleEdit.id_paymeth,
            paymeth_name: this.scheduleEdit.paymeth_name
          });
        }

        this.toggleModal();
      });
    },
    allowInsurance() {
      if (!this.form.id_paymeth)
        return true;

      let paymentMethod = this.paymentMethods.find(p => p.id_paymeth === this.form.id_paymeth);
      return paymentMethod?.is_insurance;
    },
    onStartAttendance() {
      this.$emit('onStartAttendance');
      this.$emit('onCloseModal');
      this.toggleModal();
    },
    onReopenSchedule() {
      this.$emit('onReopenSchedule');
      this.$emit('onCloseModal');
      this.toggleModal();
    },
    onFinishSchedule() {
      this.$emit('onFinishSchedule');
      this.$emit('onCloseModal');
      this.toggleModal();
    },
    confirmSchedule(schedStatus) {
      let action = schedStatus === 'CO' ? 'not-confirm' : `confirm`;
      let scheduleId = this.form.id_sched ?? this.scheduleEdit.id_sched;
      http.patch(`/places/${this.place.id_place}/schedules/${scheduleId}/${action}`).then(() => {
        Alert.toastSuccess("Agendamento confirmado com sucesso.");
        this.$emit('onConfirmSchedule');

        this.$emit('onCloseModal');
        this.toggleModal();
      }).catch((e) => {
        Alert.toastError(e.response.data);
      });
    },
    onSubmit() {
        if (!this.form.id_spec) {
            return Alert.toastError('Informe a especialidade.')
        }

        if (!this.form.sched_date) {
            return Alert.toastError('Informe a data.')
        }

        this.submiting = true;
        if (this.scheduleEdit) {
            this.updateSchedule();
            return;
        }

        this.storeSchedule();
    },
    updateSchedule() {
      this.form.id_place = this.place.id_place;
      http.put(`/places/${this.place.id_place}/schedules/${this.form.id_sched}`, this.form).then(() => {
        Alert.toastSuccess("Agendamento atualizada com sucesso.");

        this.resetForm();
        this.$emit('onCloseModal');
        this.$emit('onScheduleUpdate');

        this.toggleModal();
      }).catch((e) => {
        Alert.toastError(e.data);
      }).finally(() => {
          this.submiting = false;
      });
    },
    storeSchedule() {
        let urlCheckDuplicateSchedules = `/places/${this.place.id_place}/schedules/check`;
        http.get(urlCheckDuplicateSchedules, {
            params: {
                patient_cpf: this.form.sched_patient_cpf,
                id_spec: this.form.id_spec,
                id_prof: this.form.id_prof,
            }
        }).then((response) => {
            if (response.data?.length > 0) {
                let tableInfo = '<table class="table table-bordered">';
                tableInfo += '<thead><tr><th>Data e hora</th><th>Profissional</th><th>Especialidade</th></tr></thead><tbody>';

                response.data.forEach(item => {
                    tableInfo += `<tr><td>${this.date(item.schedule_date_time).format("DD/MM/YYYY HH:mm")}</td><td>${item.prof_name}</td><td>${item.spec_name}</td></tr>`;
                });

                tableInfo += '</tbody></table>';

                let htmlInformations = `O CPF <b>${this.form.sched_patient_cpf} (${this.form.sched_patient_name})</b>`
                    .concat('<br>')
                    .concat(`Já possui um agendamento para esse profissional ou especialidade.`)
                    .concat('<br>Tem certeza que deseja agendar?')
                    .concat(tableInfo);

                Swal.fire({
                    html: htmlInformations,
                    icon: "warning",
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    showCancelButton: true,
                    confirmButtonColor: "#780f5a",
                    confirmButtonText: "Sim, agendar",
                    cancelButtonText: "Não, revisar agendamentos",
                    width: '800px',
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.callStoreSchedule()
                    } else {
                        this.submiting = false;
                    }
                });
            } else {
                this.callStoreSchedule()
            }
        });
    },
      callStoreSchedule() {
          this.form.id_place = this.place.id_place;
          http.post(`/places/${this.place.id_place}/schedules`, this.form).then(() => {
              Alert.toastSuccess("Consulta agendada com sucesso.");

              this.$emit('onCloseModal');
              this.$emit('onScheduleStore');
              this.toggleModal();
          }).catch((e) => {
              Alert.toastError(e.response.data);
          }).finally(() => {
              this.submiting = false;
          });
      },
    cancelSchedule(whoCanceled) {
      let scheduleId = this.form.id_sched ?? this.scheduleEdit.id_sched;
      http.patch(`/places/${this.place.id_place}/schedules/${scheduleId}/cancel`, {canceled_by: whoCanceled}).then(() => {
        Alert.toastSuccess("Agendamento cancelado com sucesso.");

        this.$emit('onCloseModal');
        this.$emit('onScheduleCancel');
        this.toggleModal();
      }).catch((e) => {
        Alert.toastError(e.response.data);
      });
    },
    loadInsurances(idProf) {
      return http.get(`/places/${this.place.id_place}/professionals/${idProf}/insurances`).then((response) => {
        this.insurances = response.data.filter(insurance => insurance.enabled_to_prof_place === 1);
        return response;
      });
    },
    loadPaymentMethods(idProf) {
      let params = {from_calendar: true}
      return http.get(`/places/${this.place.id_place}/professionals/${idProf}/payment-methods`, {params: params}).then((response) => {
        this.paymentMethods = response.data.filter(payMethod => payMethod.enabled_to_prof_place === 1);
          if (this.paymentMethods.length === 1) {
              this.form.id_paymeth = this.paymentMethods[0].id_paymeth;
          }

        return response;
      });
    },
    loadSpecialties(idProf) {
      return http.get(`/places/${this.place.id_place}/professionals/${idProf}/specialties`).then((response) => {
        this.specialties = response.data;
        if (this.specialties.length === 1) {
          this.form.id_spec = this.specialties[0].id_spec;
        }

        return response;
      });
    },
    resetForm() {
      this.form = {
        sched_by: 'PL',
        sched_date: '',
        sched_time: '',
        sched_patient_name: '',
        sched_patient_cpf: '',
        sched_patient_phone: '',
        sched_obs: '',
        id_avail: '',
        id_insurance: '',
        id_paymeth: '',
        id_prof: this.userAuth.id_prof ?? '',
        id_spec: '',
        id_sched: null,
        sched_is_fitted: false,
      };

      this.datesAvailabilityToSchedule = [];
      this.timesAvailabilityToSchedule = [];

      if (this.form.id_prof) {
        this.onChangeProf(this.form.id_prof);
      }
    },
    onClickSearchedPatient(patient) {
      if (patient.patient_name)
        this.form.sched_patient_name = patient.patient_name;
      if (patient.patient_cpf)
        this.form.sched_patient_cpf = patient.patient_cpf;
      if (patient.patient_phone)
        this.form.sched_patient_phone = patient.patient_phone;
      if (patient.patient_birthdate_formatted)
        this.form.sched_patient_birthdate = patient.patient_birthdate_formatted;
      if (patient.id_insurance_last) {
          let exists = this.insurances.some(insurance => insurance.id_insurance === patient.id_insurance_last);
          if (exists)
              this.form.id_insurance = patient.id_insurance_last;
      }
    },
    toggleModal() {
        this.uniqueKey = Date.now();
        let modal = bootstrap.Modal.getOrCreateInstance(document.querySelector('#kt_modal_event'));
        modal.toggle();
    },
  }
}
</script>

<style scoped>

</style>