<template>
  <div v-if="!hasPermission('List PaymentMethod')">Você não tem permissão para acessar esta página!</div>
  <div v-else>
      <Toolbar />
      <div class="container-xxl">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <div class="d-flex align-items-center position-relative my-1">
              <span class="svg-icon svg-icon-1 position-absolute ms-6">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <rect opacity="0.5" x="17.0365" y="15.1223" width="8.15546" height="2" rx="1"
                        transform="rotate(45 17.0365 15.1223)" fill="black"></rect>
                  <path
                      d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                      fill="black"></path>
                </svg>
              </span>
                <input type="text" data-kt-user-table-filter="search" v-model="search"
                       class="form-control form-control-solid w-250px ps-14"
                       placeholder="Pesquisar ...">
              </div>
            </div>
            <div class="card-toolbar">
              <div class="col-md-6">
                <button class="btn btn-flex btn-primary m-1" data-bs-toggle="modal"
                        title="Novo convênio" data-bs-target="#modal_store_payment_method"
                        v-if="hasPermission('Store PaymentMethod')"
                        @click="formStore.id_paymeth=''">
                  <i class="bi bi-plus-lg"></i> Adicionar
                </button>
              </div>
            </div>
          </div>
          <div class="card-body py-4">
            <table class="table table-row-bordered gy-5">
              <thead>
              <tr class="fw-bold fs-6 text-muted">
                <th>Nome</th>
                <th>Tipo</th>
                <th>Desconto(%)</th>
                <th>Paciente pode agendar?</th>
                <th>Ações</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="paymentMethod in paymentMethodsFiltered">
                <td>{{ paymentMethod.paymeth_name }}</td>
                <td>{{ paymentMethod.paymeth_type }}</td>
                <td>{{ paymentMethod.place_paymeth.perc_discount ?? '0,00' }}%</td>
                <td>{{ paymentMethod.place_paymeth.allow_first_schedule ? 'Sim' : 'Não' }}</td>
                <td>
                  <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                          v-if="hasPermission('Update PaymentMethod')"
                          data-bs-toggle="modal"
                          data-bs-target="#modal_edit_payment_method"
                          @click="onClickBtnEdit(paymentMethod)"
                          title="Editar">
                    <i class="bi bi-pencil-square"></i>
                  </button>
                  <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                          v-if="hasPermission('Update PaymentMethod')"
                          @click="deletePaymentMethod(paymentMethod.id_paymeth)"
                          data-bs-toggle="tooltip" title="Excluir">
                    <i class="bi bi-trash"></i>
                  </button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="modal fade" tabindex="-1" id="modal_store_payment_method">
          <div class="modal-dialog modal-lg">
            <form method="post" @submit.prevent="submitFormStore">
              <div class="modal-content modal-lg">
                <div class="modal-header">
                  <h5 class="modal-title">Nova forma de recebimento</h5>
                  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                       aria-label="Fechar">
                    <i class="bi bi-x-lg"></i>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="col-md-12">
                    <div class="mb-6">
                      <div class="mt-4">
                        <label for="id_payment_method_store" class="required form-label">Selecione a forma de
                          recebimento</label>
                        <select class="form-control form-control-solid" id="id_payment_method_store"
                                size="5" aria-label="Selecione o convênio" v-model="formStore.id_paymeth">
                          <option :value="paymentMethod.id_paymeth" v-for="paymentMethod in allPaymentMethods">
                            {{ paymentMethod.paymeth_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-8">
                      <div class="mb-6">
                        <label for="perc_discount_store" class="form-label">Percentual de desconto</label>
                        <input type="number" class="form-control form-control-solid"
                               min="0.00" step="0.01" id="perc_discount_store" placeholder="0.00"
                               v-model="formStore.perc_discount">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mt-6">
                        <br>
                        <div class="form-check form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox"
                                 v-model="formStore.allow_first_schedule" id="allow_first_schedule_store"/>
                          <label class="form-check-label" for="allow_first_schedule_store">
                            Paciente pode agendar?
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                  <button type="submit" class="btn btn-primary">Cadastrar</button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="modal fade" tabindex="-1" id="modal_edit_payment_method">
          <div class="modal-dialog modal-lg">
            <form method="post" @submit.prevent="submitFormEdit">
              <div class="modal-content modal-lg">
                <div class="modal-header">
                  <h5 class="modal-title">Editar forma de recebimento</h5>
                  <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal"
                       aria-label="Fechar">
                    <i class="bi bi-x-lg"></i>
                  </div>
                </div>
                <div class="modal-body">
                  <div class="col-md-12">
                    <div class="mb-6">
                      <div class="mt-4">
                        <label for="id_payment_method_update" class="required form-label">Selecione o Forma de
                          recebimento</label>
                        <select class="form-control form-control-solid" id="id_payment_method_update"
                                aria-label="Selecione o convênio" v-model="formEdit.id_paymeth">
                          <option :value="paymentMethod.id_paymeth" v-for="paymentMethod in allPaymentMethods">
                            {{ paymentMethod.paymeth_name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-4">
                    <div class="col-md-8">
                      <div class="mb-6">
                        <label for="perc_discount_update" class="form-label">Percentual de desconto</label>
                        <input type="number" class="form-control form-control-solid"
                               min="0.00" step="0.01" id="perc_discount_update" placeholder="0.00"
                               v-model="formEdit.perc_discount">
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="mt-6">
                        <br>
                        <div class="form-check form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox"
                                 v-model="formEdit.allow_first_schedule" id="allow_first_schedule_update"/>
                          <label class="form-check-label" for="allow_first_schedule_update">
                            Paciente pode agendar?
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
                  <button type="submit" class="btn btn-primary">Salvar</button>
                </div>
              </div>
            </form>
          </div>
        </div>

      </div>
  </div>

</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Permission from "../../helpers/permission";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "PaymentMethod",
    components: {
        Toolbar,
    },
  data() {
    return {
      search: '',
      paymentMethods: [],
      allPaymentMethods: [],
      formStore: {
        id_paymeth: '',
        perc_discount: '',
        allow_first_schedule: true,
      },
      formEdit: {
        id_paymeth: '',
        perc_discount: '',
        allow_first_schedule: true,
      },
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    paymentMethodsFiltered() {
      return this.paymentMethods.filter(paymentMethod => {
        return paymentMethod.paymeth_name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
  },
  mounted() {
    this.loadAllPaymentMethods();
    this.loadPaymentMethods();
  },
  methods: {
    hasPermission(permission) {
      return Permission.has(permission);
    },
    loadAllPaymentMethods() {
      http.get(`/payment-methods`).then((response) => {
        this.allPaymentMethods = response.data;
      });
    },
    loadPaymentMethods() {
      http.get(`/places/${this.place.id_place}/payment-methods`).then((response) => {
        this.paymentMethods = response.data;
      });
    },
    submitFormStore() {
      http.post(`/places/${this.place.id_place}/payment-methods`, this.formStore).then(_ => {
        this.loadPaymentMethods();
        Alert.toastSuccess("Forma de recebimento cadastrada com sucesso.");

        this.formStore = {
          id_paymeth: '',
          perc_discount: '',
          allow_first_schedule: true,
        };

        bootstrap.Modal.getInstance(document.getElementById('modal_store_payment_method')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data);
      });
    },
    submitFormEdit() {
      const placePayment = {
        "id_paymeth": this.formEdit.id_paymeth,
        "perc_discount": this.formEdit.perc_discount,
        "allow_first_schedule": this.formEdit.allow_first_schedule,
      };
      http.put(`/places/${this.place.id_place}/payment-methods/${this.formEdit.id_paymeth}`, placePayment).then(() => {
        this.loadPaymentMethods();
        Alert.toastSuccess("Forma de recebimento atualizada com sucesso.");

        bootstrap.Modal.getInstance(document.getElementById('modal_edit_payment_method')).toggle();
      });
    },
    onClickBtnEdit(paymentMethod) {
      this.formEdit.id_paymeth = paymentMethod.id_paymeth;
      this.formEdit.perc_discount = paymentMethod.place_paymeth.perc_discount;
      this.formEdit.allow_first_schedule = paymentMethod.place_paymeth.allow_first_schedule;
    },
    deletePaymentMethod(paymentMethodId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover a forma de recebimento?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/places/${this.place.id_place}/payment-methods/${paymentMethodId}`).then(_ => {
            this.loadPaymentMethods();
            Alert.toastSuccess("Forma de recebimento excluída com sucesso.");

            bootstrap.Modal.getInstance(document.getElementById('modal_delete_payment_method')).toggle();
          }).catch(e => {
            Alert.toastError(e.response.data);
          });
        }
      });
    },
  }
}
</script>

<style scoped>

</style>