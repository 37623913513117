<template>
    <div id="kt_calendar_app">
      <p class="text-center h6" style="margin-bottom: -5px; margin-left: -100px; margin-top: -20px;">
        {{schedulesCount}} Agendamento(s)
      </p>

      <FullCalendar ref="fullCalendar" :options="calendarOptions"/>
    </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listGridPlugin from '@fullcalendar/list';
import brLocale from '@fullcalendar/core/locales/pt-br';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import http from "../../helpers/http";

export default {
    name: "Calendar",
    components: {
        FullCalendar,
    },
    props: ['professional', 'idSpec', 'status', 'uniqueKey', 'goToDate'],
    emits: ['onEventClick', 'onDateClick'],
    data() {
        return {
            nextDateWithSchedule: null,
            prevDateWithSchedule: null,
            schedulesCount: 0,
        }
    },
    computed: {
        filters() {
            return {
                id_prof: null,
                id_spec: null,
                sched_status: null,
                start_date: '',
                end_date: '',
                base: 'AL',
            }
        },
        place() {
            return this.$store.state.auth.place;
        },
        calendarOptions() {
            return {
                themeSystem: 'bootstrap5',
                locale: brLocale,
                plugins: [bootstrap5Plugin, dayGridPlugin, interactionPlugin, timeGridPlugin, listGridPlugin],
                customButtons: {
                    customPrev: {
                        icon: 'chevron-left',
                        click: this.customPrev
                    },
                    customNext: {
                        icon: 'chevron-right',
                        click: this.customNext
                    }
                },
                headerToolbar: {
                    left: 'customPrev,customNext today',
                    center: 'title',
                    right: 'timeGridDay,timeGridWeek,dayGridMonth,listDay'
                },
                initialView: 'timeGridDay',
                slotMinTime: this.professional?.time_begin ?? '00:00:00',
                slotMaxTime: this.professional?.time_end ? this.endTime(this.professional?.time_end, this.professional?.interval_minutes) : '23:59:00',
                slotDuration: this.professional?.interval_minutes ? this.formatTimeFromMinutes(this.professional.interval_minutes) : '00:10:00',
                slotLabelInterval: this.professional?.interval_minutes ? this.formatTimeFromMinutes(this.professional.interval_minutes) : '00:10:00',
                slotLabelFormat: {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false
                },
                eventClick: this.onEventClick,
                dateClick: this.onDateClick,
                weekends: true,
                nowIndicator: true,
                firstDay: new Date().getDay(),
                allDaySlot: false,
                scrollTime: moment().format("HH:mm:ss"),
                now: new Date(),
                height: 'auto',
                events: this.loadEventsFullCalendar,
            }
        }
    },
    watch: {
        professional() {
            this.refetchEvents();
        },
        idSpec() {
            this.refetchEvents();
        },
        status() {
            this.refetchEvents();
        },
        uniqueKey() {
            this.refetchEvents();
        },
        goToDate(newDate) {
            if (!newDate)
                return;

            let calendarApi = this.$refs.fullCalendar.getApi();
            calendarApi.gotoDate(newDate);
        },
    },
    methods: {
        customPrev() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            if (this.prevDateWithSchedule && ['timeGridDay', 'listDay'].includes(calendarApi.view.type)) {
                calendarApi.gotoDate(this.prevDateWithSchedule);
            } else {
                calendarApi.prev();
            }
        },
        customNext() {
            let calendarApi = this.$refs.fullCalendar.getApi();
            if (this.nextDateWithSchedule && ['timeGridDay', 'listDay'].includes(calendarApi.view.type)) {
                calendarApi.gotoDate(this.nextDateWithSchedule);
            } else {
                calendarApi.next();
            }
        },
        onDateClick: function (info) {
            this.$emit('onDateClick', info.date);
        },
        onEventClick: function (info) {
            if (!info.event.extendedProps.id_sched)
                return;
            let scheduleClicked = info.event.extendedProps;
            this.$emit('onEventClick', scheduleClicked);
        },
        refetchEvents() {
            this.filters.id_prof = this.professional?.id_prof;
            this.filters.id_spec = this.idSpec;
            this.filters.sched_status  = this.status;

            if (this.$refs.fullCalendar) {
                this.$refs.fullCalendar.getApi().refetchEvents();
            }
        },
        filterSchedules() {
            if (!this.filters.id_prof)
                return new Promise((resolveInner) => {
                    setTimeout(resolveInner, 1);
                });

            let url = `/places/${this.place.id_place}/schedules`;
            return http.get(url, {params: this.filters}).then((response) => {
                this.getNextDateFromCalendar();
                return response;
            });
        },
        getNextDateFromCalendar() {
            if (!this.filters.id_prof)
                return;

            let url = `/places/${this.place.id_place}/schedules/next-date`;
            http.get(url, {params: this.filters}).then((r) => {
                this.nextDateWithSchedule = r.data.next_date;
                this.prevDateWithSchedule = r.data.prev_date;
            });
        },
        loadEventsFullCalendar(fetchInfo, successCallback) {
            this.filters.start_date = fetchInfo.start;
            this.filters.end_date = fetchInfo.end;

            return this.filterSchedules().then((response) => {
                if (!response) {
                    successCallback([]);
                    return response;
                }

                let schedules = response.data.map((schedule) => {

                    let title = schedule.id_sched ? schedule.patient_name ?? '' : 'BLOQUEADO';

                  if(title !== 'BLOQUEADO') {
                    title = title.concat(' | ').concat(schedule.kind_exam ? 'E' : 'C');
                  }

                    if(schedule.paymeth_name) {
                      title = title.concat(' | ')
                          .concat(schedule.paymeth_name);
                    }

                    if(schedule.insurance_name) {
                        title = title.concat(' | ')
                            .concat(schedule.insurance_name);
                    }

                    let parsedDate = moment(schedule.schedule_date_time, "YYYY-MM-DDTHH:mm");
                    parsedDate.add(schedule.interval_minutes ?? this.professional?.interval_minutes, 'minutes');
                    let endDate = parsedDate.format("YYYY-MM-DDTHH:mm");

                    return {
                        title: title,
                        start: schedule.schedule_date_time,
                        end: endDate,
                        id: schedule.id_sched,
                        allDay: false,
                        backgroundColor: schedule.sched_status_color,
                        ...schedule
                    };
                });

                this.schedulesCount = schedules.length;
                successCallback(schedules);
            });
        },
        endTime(endTime, interval) {
            let partes = endTime.split(':');

            let data = new Date();
            data.setHours(parseInt(partes[0]));
            data.setMinutes(parseInt(partes[1]));
            data.setSeconds(parseInt(partes[2]));

            data.setMinutes(data.getMinutes() + interval);

            return data.toTimeString().split(' ')[0];
        },
        formatTimeFromMinutes(minutes) {
            const totalSeconds = minutes * 60;
            const hours = Math.floor(totalSeconds / 3600);
            const mins = Math.floor((totalSeconds % 3600) / 60);
            const secs = totalSeconds % 60;

            return [
                hours.toString().padStart(2, '0'),
                mins.toString().padStart(2, '0'),
                secs.toString().padStart(2, '0')
            ].join(':');
        },
    }
}
</script>

<style>
.fc-toolbar .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
    border: 0;
    padding: calc(.4rem + 1px) calc(0.6rem + 1px);
}

:root {
    --bs-gray-200: #f5f8fa;
    --bs-gray-400: #c9c6c6;
}

div.fc-timegrid-slots > table > tbody > tr {
    height: 3.5em !important;
    border-bottom: 0 !important;
}

.fc-day-past {
    background-color: #F5F8FA;
}

.fc-event {
    cursor: pointer;
}

.popover {
    max-width: 600px !important;
}

.fc .fc-toolbar-title {
    font-size: 1.1em;
}

</style>