<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">
      <div class="d-flex flex-column flex-lg-row-auto w-xl-600px positon-xl-relative bg-ubbi" style="background-color: #780f5a;">
        <div class="d-flex flex-column position-xl-fixed top-0 bottom-0 w-xl-600px scroll-y">
          <div class="d-flex flex-row-fluid flex-column text-center p-10" style="padding-top: 0!important">
            <a v-on:click.prevent="" href="#" class="py-9 mb-5" style="padding-top: 0!important">
              <img alt="Logo" :src="require('@/assets/images/logo_ubbi_white.png')" class="h-200px">
            </a>
            <h1 class="fw-bolder fs-2qx pb-5 pb-md-10 text-light">
              O software médico <br>feito para você
            </h1>
            <p class="fw-bold fs-2 text-light">
              Todas as ferramentas que o seu consultório precisa para crescer, em um único lugar.
            </p>
          </div>
          <div class="d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
               :style="{backgroundImage:`url(`+require('@/assets/images/1.svg')+`)`}"></div>
        </div>
      </div>

      <div class="d-flex flex-column flex-lg-row-fluid py-10">
        <div class="d-flex flex-center flex-column flex-column-fluid">
          <div class="card">
            <div class="">
              <div class="w-lg-700px p-10 p-lg-5 mx-auto">

                  <div class="mb-10 text-center">
                    <h1 class="text-dark mb-3">Criar conta</h1>
                    <div class="text-gray-400 fw-bold fs-4">Já tem uma conta?
                      <router-link to="/login" class="link-primary fw-bolder">Faça login</router-link>
                    </div>
                  </div>

                <StepperRegister @onRegister="onRegister"/>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex flex-center flex-wrap fs-6 p-5 pb-0">
          <div class="d-flex flex-center fw-bold fs-6">
            <a href="https://ubbimed.com.br" class="text-muted text-hover-primary px-2">Site</a>
            <a href="https://ubbimed.com.br/#us" class="text-muted text-hover-primary px-2">Sobre</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepperRegister from "./StepperRegister";

export default {
  name: "Register",
  components: {
    StepperRegister
  },
  created() {
    if (this.$store.getters['auth/isAuth'])
      this.$router.push('/dashboard');
  },
  methods: {
    onRegister() {
      this.$router.push('/dashboard')
    }
  }
}
</script>

<style scoped>

</style>
