<template>
  <div>
      <Toolbar />
      <div class="container-xxl">
        <div class="d-flex flex-column flex-xl-row">
          <div class="flex-column" v-show="isExpandPatientData">
              <button type="button" class="btn btn-white btn-active-light-primary btn-sm mr-10"
                      @click="toggleExpandPatientData">
                <i class="bi bi-arrow-right-circle"></i>
              </button>
          </div>
          <div class="flex-column flex-lg-row-auto w-100 mb-10" :class="this.isScheduleOnline ? 'w-xl-500px' : 'w-xl-300px' " v-show="!isExpandPatientData">

            <PatientCard :schedule="schedule" :patient="schedule?.patient"
                         @onToggleExpandPatientData="toggleExpandPatientData">
              <template #image v-if="this.isScheduleOnline">
                  <JitsiMeeting v-if="this.waitingRoom"
                                :roomName="this.waitingRoom.wr_code"
                                :domain="this.waitingRoom.wr_domain"
                                :jwt="this.waitingRoom.wr_jwt"
                                :configOverwrite="{
                                    subject: 'Teleconsulta',
                                    hideConferenceSubject: false,
                                    disableProfile: true,
                                    startWithVideoMuted: false,
                                    hideConferenceTimer: true,
                                    defaultLocalDisplayName: 'Você',
                                    toolbarButtons: [
                                        'microphone', 'camera', 'desktop', 'fullscreen',
                                        'hangup', 'toggle-camera',
                                        'etherpad', 'raisehand',
                                        'select-background', 'tileview',
                                    ],
                                }"
                                :interfaceConfigOverwrite="{
                                    APP_NAME: 'Ubbimed',
                                    MOBILE_APP_PROMO: false,
                                    SHOW_JITSI_WATERMARK: false,
                                    DEFAULT_BACKGROUND: '#780f5a',
                                    HIDE_INVITE_MORE_HEADER: true,
                                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                    SETTINGS_SECTIONS: ['devices'],
                                    LANG_DETECTION: false,
                                }"
                                lang="ptBR"
                                width="100%"
                                height="400px"
                                :userInfo="{
                                    displayName: this.userAuth.user_name,
                                    email: this.userAuth.user_email,
                                }"
                                @onApiReady="(externalApi) => handleApiReady(externalApi)"
                                @onReadyToClose="handleReadyToClose"
                                @getIframeRefOnApiReady="handleJitsiIFrameRef1"
                  />
              </template>
            </PatientCard>
          </div>

          <div class="flex-lg-row-fluid ms-lg-5">
            <ul class="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-bold mb-8">
              <li class="nav-item">
                <a class="nav-link text-active-primary pb-4 active" data-bs-toggle="tab"
                   href="#tab-attendance">
                  Atendimento
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-active-primary pb-4" data-kt-countup-tabs="true"
                   data-bs-toggle="tab" href="#tab-history">
                  Histórico
                </a>
              </li>
              <li class="nav-item ms-auto">
                <a href="#" @click.prevent class="btn btn-secondary ps-7" data-kt-menu-trigger="click"
                   data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                  Novo
                  <span class="svg-icon svg-icon-2 me-0">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z" fill="currentColor"></path>
                    </svg>
                  </span>
                </a>
                <button type="button" class="btn btn-light-danger ps-7 ms-3 border-danger" @click="finishAttendance">
                  Encerrar
                  <span class="svg-icon svg-icon-2">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <rect opacity="0.3" x="4" y="11" width="12" height="2" rx="1" fill="currentColor"/>
                      <path d="M5.86875 11.6927L7.62435 10.2297C8.09457 9.83785 8.12683 9.12683 7.69401 8.69401C7.3043 8.3043 6.67836 8.28591 6.26643 8.65206L3.34084 11.2526C2.89332 11.6504 2.89332 12.3496 3.34084 12.7474L6.26643 15.3479C6.67836 15.7141 7.3043 15.6957 7.69401 15.306C8.12683 14.8732 8.09458 14.1621 7.62435 13.7703L5.86875 12.3073C5.67684 12.1474 5.67684 11.8526 5.86875 11.6927Z" fill="currentColor"/>
                      <path d="M8 5V6C8 6.55228 8.44772 7 9 7C9.55228 7 10 6.55228 10 6C10 5.44772 10.4477 5 11 5H18C18.5523 5 19 5.44772 19 6V18C19 18.5523 18.5523 19 18 19H11C10.4477 19 10 18.5523 10 18C10 17.4477 9.55228 17 9 17C8.44772 17 8 17.4477 8 18V19C8 20.1046 8.89543 21 10 21H19C20.1046 21 21 20.1046 21 19V5C21 3.89543 20.1046 3 19 3H10C8.89543 3 8 3.89543 8 5Z" fill="#C4C4C4"/>
                    </svg>
                  </span>
                </button>
                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold py-4 w-250px fs-6"
                     data-kt-menu="true">
                  <div class="menu-item px-5">
                    <div class="menu-content text-muted pb-2 px-5 fs-7 text-uppercase">
                      Tipo de Registro
                    </div>
                  </div>
                  <div class="menu-item px-5" v-for="recordType in recordTypes">
                    <a v-if="!existsMedicalRecord(recordType)" href="#" class="menu-link px-5" @click.prevent="storeMedicalRecord(recordType)">
                      {{recordType.rectype_name}}
                    </a>
                  </div>
                </div>
              </li>
            </ul>

            <div class="tab-content w-100">
              <div class="tab-pane fade show active" id="tab-attendance" role="tabpanel">
                <AttendanceTab :medical-records="medicalRecords"
                               :schedule="schedule"
                               :exam-protocols="examProtocols"
                               :prescription-protocols="prescriptionProtocols"
                               :presc-types="prescTypes"
                               :drugs-admins="drugAdmins"
                               :documentTemplates="documentTemplates"
                               :healthIndicatorGroups="healhImdicatorGroups"
                               :key="'attendance-' + schedule?.id_sched"
                               :certifiers="certifiers"/>
              </div>
              <div class="tab-pane fade" id="tab-history" role="tabpanel">
                <History :record-types="recordTypes"
                         :schedule="schedule"
                         :exam-protocols="examProtocols"
                         :prescription-protocols="prescriptionProtocols"
                         :presc-types="prescTypes"
                         :drugs-admins="drugAdmins"
                         :documentTemplates="documentTemplates"
                         :healthIndicatorGroups="healhImdicatorGroups"
                         :key="'history-' + schedule?.id_sched"
                         :certifiers="certifiers"/>
              </div>
            </div>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
import AttendanceTab from './tab/Attendance';
import PatientCard from "./shared/PatientCard";
import History from './tab/History';
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import { JitsiMeeting } from "@jitsi/vue-sdk";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";

export default {
  name: "Attendance",
  components: {
      AttendanceTab,
      History,
      PatientCard,
      JitsiMeeting,
      Toolbar,
  },
  data() {
    return {
      schedule: null,
      medicalRecords: [],
      recordTypes: [],
      examProtocols: [],
      prescriptionProtocols: [],
      prescTypes: [],
      drugAdmins: [],
      documentTemplates: [],
      healhImdicatorGroups: [],
      certifiers: [],
      isExpandPatientData: false,
      scheduleId: null,
      professionalId: null,
      waitingRoom: null,
      isScheduleOnline: false,
      height: null,
      jistiReference: null,
    }
  },
  async created() {
    this.professionalId = this.userAuth.id_prof
    this.scheduleId = this.$route.params.scheduleId;
    this.findSchedule().then(() => {
      this.isScheduleOnline = this.schedule?.sched_online;
      if (this.isScheduleOnline) {
        this.getWaitingRoom();
      }

      this.getMedicalRecords();
      this.getRecordTypes();
      this.getExamProtocols();
      this.getPrescriptionProtocols();
      this.getPrescTypes();
      this.getDrugAdmin();
      this.getDocumentTemplates();
      this.getHealthIndicatorGroups();
      this.getCertifiers();
    });
  },
  mounted() {
    KTDrawer.createInstances();
  },
  beforeUnmount() {

  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
    getWaitingRoom() {
      return http.get(`/places/${this.place.id_place}/professionals/${this.professionalId}/waiting-rooms/opened`).then((response) => {
        this.waitingRoom = response.data;

        return response;
      });
    },
    finishCall() {
      http.patch(`/places/${this.place.id_place}/professionals/${this.professionalId}/attendance/schedules/${this.scheduleId}/finish-call`).then((response) => {
        Alert.success(
            'O paciente saiu da chamada e não pode mais entrar na sala de atendimento.',
            'Chamada finalizada'
        );
      });
    },
    finishAttendance() {
      Swal.fire({
        title: 'Tem certeza que deseja finalizar o atendimento?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, Finalizar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: 'btn btn-secundary'
        }
      }).then(async (result) => {
          if (result.isConfirmed) {

            if (this.schedule.sched_online) {
              const { value: schedSuccessfull } = await Swal.fire({
                width: 600,
                allowOutsideClick: false,
                title: 'A consulta foi realizada com sucesso?',
                input: 'radio',
                inputOptions: {
                  'yes': "Sim, Consegui atender o paciente. <br>",
                  'no': "Não, Permitir que o paciente reagende a consulta.<br>",
                },
                inputValidator: (value) => {
                  if (!value) {
                    return 'Informe se a consulta foi realizada com sucesso.'
                  }
                },
                icon: 'warning',
                confirmButtonText: 'Finalizar Atendimento',
                customClass: {
                  confirmButton: "btn btn-success",
                }
              });

              let isSchedSuccessfull = schedSuccessfull === 'yes';

              http.patch(`/places/${this.place.id_place}/professionals/${this.professionalId}/attendance/schedules/${this.scheduleId}/finish`, {sched_successfull: isSchedSuccessfull}).then((response) => {
                this.schedule = response.data;
                this.$router.push('/attendances');
              });
            } else {
              http.patch(`/places/${this.place.id_place}/professionals/${this.professionalId}/attendance/schedules/${this.scheduleId}/finish`).then((response) => {
                this.schedule = response.data;
                this.$router.push('/attendances');
              });
            }

          }
      });
    },
    toggleExpandPatientData() {
      this.isExpandPatientData = !this.isExpandPatientData;
    },
    findSchedule() {
      return http.get(`/places/${this.place.id_place}/professionals/${this.professionalId}/attendance/schedules/${this.scheduleId}`).then((response) => {
        this.schedule = response.data;

        return response;
      }).catch((e) => {
        let title = e.response.status === 404 ? 'Agendamento não encontrado.' : 'Falha ao carregar o agendamento.';

        Swal.fire({
          title: title,
          text: 'O agendamento não existe ou não pode mais ser atendido.',
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
          customClass: {
            confirmButton: "btn btn-danger",
          }
        }).then(() => {
          this.$router.push('/attendances');
        });

      });
    },
    getRecordTypes() {
      if (!this.professionalId)
        return;

      http.get(`/professionals/${this.professionalId}/record-types/actives`).then((response) => {
        this.recordTypes = response.data;
      });
    },
    getMedicalRecords() {
      if (!this.scheduleId)
        return;

      if (!this.schedule?.id_prof)
        return;

      return http.get(`/places/${this.place.id_place}/schedules/${this.scheduleId}/medical-records`).then((response) => {
        this.medicalRecords = response.data;

        return response;
      });
    },
    storeMedicalRecord(recordType) {
      let payload = {
        "id_rectype": recordType.id_rectype,
        "id_prof": recordType.id_prof,
        "id_patient": this.schedule.id_patient,
        "id_sched": this.scheduleId,
        "id_place": this.place.id_place,
      };

      http.post(`/professionals/${this.professionalId}/patients/${this.schedule.id_patient}/medical-records`, payload).then((response) => {
        this.getMedicalRecords();
      });
    },
    getPrescriptionProtocols() {
      if (!this.schedule?.id_prof)
        return;

      http.get(`/professionals/${this.schedule?.id_prof}/prescription-protocols`).then((response) => {
        this.prescriptionProtocols = response.data;
      });
    },
    getPrescTypes() {
      this.precrTypes = [];
      http.get(`/prescription-types`).then((response) => {
        this.prescTypes = response.data;
      });
    },
    getDrugAdmin() {
      http.get(`drug-administrations`).then((response) => {
        this.drugAdmins = response.data;
      });
    },
    getExamProtocols() {
      if (!this.schedule?.id_prof)
        return;

      http.get(`/professionals/${this.schedule?.id_prof}/exam-protocols`).then((response) => {
        this.examProtocols = response.data;
      });
    },
    getDocumentTemplates() {
      if (!this.schedule?.id_prof)
        return;

      http.get(`/places/${this.place.id_place}/professionals/${this.schedule?.id_prof}/document-templates`).then((response) => {
        this.documentTemplates = response.data;
      });
    },
    getHealthIndicatorGroups() {
      if (!this.schedule?.id_prof)
        return;

      http.get(`/professionals/${this.schedule?.id_prof}/health-indicator-groups`).then((response) => {
        this.healhImdicatorGroups = response.data;
      });
    },
    existsMedicalRecord(recordType) {
      return this.medicalRecords.findIndex(({id_rectype}) => id_rectype === recordType.id_rectype ) >= 0;
    },
    getCertifiers() {
      http.get(`/digital-certificates/certifiers`).then((response) => {
        this.certifiers = response.data;
      });
    },
      handleApiReady(jistiReference) {
          this.jistiReference = jistiReference;
          // adicionar listen nos eventos do jitsi
      },
      handleReadyToClose() {
          this.finishCall();
      },
      handleJitsiIFrameRef1(iframeRef) {
          iframeRef.style.width = '100%';
          iframeRef.style.height = '400px';
          // customizar o iframe do jitsi
      },
  }
}
</script>

<style>
.swal2-radio {
  display: block !important;
}
</style>