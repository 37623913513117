<template>
    <div class="modal fade" tabindex="-1" id="modal_patient" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-lg">
            <form method="post" @submit.prevent="submitFormEdit">
                <div class="modal-content modal-lg">
                    <div class="modal-header">
                        <h5 class="modal-title">Paciente </h5> <h4>{{patient?.patient_name}}</h4>
                        <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
                             @click="$emit('onCloseModal')"
                             data-bs-dismiss="modal" aria-label="Fechar">
                            <i class="bi bi-x-lg"></i>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fs-6 fw-bold mb-2 required">Nome</label>
                                <input type="text" class="form-control form-control-solid"
                                       placeholder="Ex. João dos Santos" v-model="form.patient_name"/>
                            </div>
                            <div class="col-md-6">
                                <label class="fs-6 fw-bold mb-2 required">CPF</label>
                                <input type="text" class="form-control form-control-solid"
                                       v-mask="'###.###.###-##'" required
                                       placeholder="Ex. 43398419877" v-model="form.patient_cpf"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fs-6 fw-bold mb-2 required">Celular</label>
                                <input type="text" class="form-control form-control-solid"
                                       v-mask="['(##) ####-####', '(##) #####-####']" required
                                       placeholder="Ex. 1998551-4614" v-model="form.patient_phone"/>
                            </div>
                            <div class="col-md-6">
                                <label class="fs-6 fw-bold mb-2 required">Data Nascimento</label>
                                <input type="text" class="form-control form-control-solid"
                                       v-mask="'##/##/####'" required
                                       placeholder="Ex. 08/12/1990" v-model="form.patient_birthdate"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="fs-6 fw-bold mb-2">E-mail</label>
                                <input type="email" class="form-control form-control-solid"
                                       placeholder="Ex. joao@gmail.com" v-model="form.patient_email"/>
                            </div>
                            <div class="col-md-6">
                                <label class="fs-6 fw-bold mb-2">RG</label>
                                <input type="text" class="form-control form-control-solid"
                                       placeholder="Ex. 54497955-6" v-model="form.patient_rg"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label class="fs-6 fw-bold mb-2">Observação</label>
                                <textarea type="text" class="form-control form-control-solid"
                                          placeholder="..." v-model="form.observations"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary">Atualizar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import http from "../../helpers/http";
import {mask} from "vue-the-mask";
import Alert from "../../helpers/alert";

export default {
    name: "ModalPatient",
    props: ['patient', 'openModal'],
    emits: ['onCloseModal'],
    directives: {
        mask,
    },
    data() {
        return {
            form: {
                patient_name: null,
                patient_cpf: null,
                patient_phone: null,
                patient_birthdate: null,
                observations: null,
                patient_email: null,
                patient_rg: null,
            }
        }
    },
    mounted() {
        if (this.patient != null && this.openModal) {
            this.form = {
                patient_name: this.patient.patient_name,
                patient_cpf: this.patient.patient_cpf,
                patient_phone: this.patient.patient_phone,
                patient_birthdate: this.date(this.patient.patient_birthdate).format('DD/MM/YYYY'),
                observations: this.patient.observations,
                patient_email: this.patient.patient_email,
                patient_rg: this.patient.patient_rg,
            };

            this.toggleModal();
        }
    },
    computed: {
        place() {
            return this.$store.state.auth.place;
        },
    },
    watch: {
        openModal(newValue) {
            if (newValue) {
                this.toggleModal();
            }
        },
        patient(newValue) {
            if (newValue == null)
                return;

            this.form = {
                patient_name: newValue.patient_name,
                patient_cpf: newValue.patient_cpf,
                patient_phone: newValue.patient_phone,
                patient_birthdate: this.date(newValue.patient_birthdate).format('DD/MM/YYYY'),
                observations: newValue.observations,
                patient_email: newValue.patient_email,
                patient_rg: newValue.patient_rg,
            }
        }
    },
    methods: {
        date: moment,
        submitFormEdit() {
            http.put(`/places/${this.place.id_place}/patients/${this.patient.id_patient}`, this.form).then(() => {
                Alert.toastSuccess('Paciente atualizado com sucesso.');

                this.$emit('onCloseModal');
                this.toggleModal();
            }).catch((e) => {
                Alert.toastError(e.response.data.message);
            });
        },
        toggleModal() {
            let modal = bootstrap.Modal.getOrCreateInstance(document.querySelector('#modal_patient'));
            modal.toggle();
        },
    }
}
</script>

<style scoped>

</style>