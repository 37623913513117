<template>
  <div class="modal fade" tabindex="-1" id="modal_welcome"
       data-bs-backdrop="static" data-bs-keyboard="false"
       aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Configuração inicial
          </h5>
          <!-- <div class="btn btn-icon btn-sm btn-active-light-primary ms-2"
               @click="completeWelcomeModal"
               data-bs-dismiss="modal" aria-label="Fechar">
            <i class="bi bi-x-lg"></i>
          </div> -->
        </div>
        <div class="modal-body">
          <div class="row">

            <div class="col-md-12" v-show="currentStep === 'initial'">

              <div class="row text-center">
                <p class="h3" style="z-index: 1">Parabéns, o seu cadastro foi realizado <br>com sucesso!</p>
                <div style="margin-top: -50px">
                  <video class="" width="300" height="300" autoplay playsinline muted>
                    <source :src="require('@/assets/mp4/95088-success.mp4')" type="video/mp4"/>
                  </video>
                </div>
              </div>

              <div style="margin-top: -50px">
                <p class="h3">Para finalizar, faltam apenas 2 passos:</p>
                <br>
                <ol>
                  <li>
                    Vamos validar seus dados e identidade.
                    Tenha em mãos seu RG ou CNH e o Registro Profissional (carteira do Conselho); <br><br>
                  </li>
                  <li>
                    Vamos <b>definir seus dias e horários de atendimento</b>; <br><br>
                  </li>
                  <!-- <li>
                    Vamos <b>configurar sua conta do Mercado Pago para que você possa receber</b>
                    pelas consultas pagas <b>em Cartão de Crédito</b>; <br>
                  </li> -->
                </ol>
                <br>
              </div>

              <div class="row">
                <div class="col-md-12 text-center">
                  <button class="btn btn-primary" @click="nextStep">
                    Vamos lá
                    <i class="bi bi-arrow-right-short"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="col-md-12" v-show="currentStep === 'documents'">
              <div class="row text-center">
                <p class="h3">Documentos Pessoais</p>
                <br>
              </div>

              <div class="pb-10 pb-lg-12">
                <div class="text-muted fw-semibold fs-4">
                  <small>
                    Faça o envio do seu RG ou CNH e o Registro Profissional (carteira do Conselho).
                  </small>
                </div>
              </div>

              <div class="fv-row mb-10">
                <div class="row">
                  <div class="col-md-6" v-for="documentType in documentTypes">

                    <DropZone :headers="headers"
                              :key="documentType.id_doc_type"
                              :acceptedFiles="['doc', 'image', 'pdf']"
                              :maxFiles="2"
                              ref="documentTypes"
                              class="h-40 border-primary border border-dashed p-2"
                              :url="endpointUpload + `&id_doc_type=${documentType.id_doc_type}`"
                              @uploaded="onUploaded($event)"
                              @errorUpload="onErrorUpload()">
                      <template v-slot:message>
                        <p class="mt-0">
                          <img :src="require('@/assets/images/files/upload.svg')" class="theme-light-show" alt=""><br><br>
                          Arraste aqui para enviar o <b> {{ documentType.doc_type_name }} </b>
                        </p>
                      </template>
                    </DropZone>

                    <div class="card document-container mt-4 mb-4"
                         v-for="item in existingDocuments[documentType.id_doc_type]"
                         :key="item.id_prof_document">
                      <label v-if="item.id_prof_document"
                          class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow delete-button"
                          data-bs-toggle="tooltip" title="excluir"
                          @click="deleteFile(documentType.id_doc_type, item.id_prof_document)">
                        <i class="bi bi-trash-fill fs-1"></i>
                      </label>
                     <img v-if="item.file_thumbnail && item.id_prof_document" class="me-2"
                           :src="`data:image/png;base64,` + item.file_thumbnail"
                           :key="item.id_prof_document"
                           style="max-width: 150px; max-height: 150px; border-radius: 20px;">
                     <img v-if="(!item.file_thumbnail) && item.id_prof_document" :src="getFileSvg(item.file_mime_type)" class="theme-light-show" alt="" style="width: 150px; height: 150px; border-radius: 20px;">
                    </div>

                  </div>
                </div>
              </div>

              <div class="d-flex flex-stack pt-10">
                <div class="me-2">
                  <button type="button" @click="previvousStep" class="btn btn-lg btn-light-primary me-3">
                    <span class="svg-icon svg-icon-3 me-1">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
												<path
                            d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                            fill="currentColor"></path>
											</svg>
										</span>
                    Voltar
                  </button>
                </div>
                <div>
                  <button type="button" @click="nextStep" class="btn btn-lg btn-primary">Próximo
                    <span class="svg-icon svg-icon-3 ms-1 me-0">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                              fill="currentColor"></rect>
												<path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="currentColor"></path>
											</svg>
										</span>
                  </button>
                </div>
              </div>

            </div>

            <!-- <div class="col-md-12" v-show="currentStep === 'hours'">
              <div class="row text-center">
                <p class="h3">Vamos configurar sua agenda?</p>
                <br>
              </div>

              <div class="pb-10 pb-lg-12">
                <div class="text-dark fw-semibold fs-4">
                  <small>
                    Antes, precisamos saber como é sua rotina:
                  </small>
                </div>
              </div>

              <div>
                <input type="radio" class="btn-check" v-model="formAvailWeek.avail_type"
                       value="VA" id="kt_radio_buttons_2_option_2"/>
                <label class="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center"
                       for="kt_radio_buttons_2_option_2">
                  <span class="svg-icon svg-icon-4x me-4">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      </svg>
                  </span>
                  <span class="d-block fw-semibold text-start">
                      <span class="text-dark fw-bold d-block fs-3"><b>AGENDA DINÂMICA</b></span>
                      <span class="text-muted fw-semibold fs-6">
                        <b>Sua agenda se altera com frequência em razão de plantões e cirurgias?</b>
                        <br>
                        Neste caso, utilize a opção <b>Configurar Agenda Dinâmica</b>. Com ela você deverá informar a
                        cada semana, quinzena ou mês, por exemplo, os dias e horários de atendimento, de acordo com sua disponibilidade.
                      </span>
                  </span>
                </label>
                <input type="radio" class="btn-check" v-model="formAvailWeek.avail_type"
                       value="FI" id="kt_radio_buttons_2_option_1"/>
                <label
                    class="btn btn-outline btn-outline-dashed btn-active-light-primary p-7 d-flex align-items-center mb-5 mt-5"
                    for="kt_radio_buttons_2_option_1">
                  <span class="svg-icon svg-icon-4x me-4">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      </svg>
                  </span>
                  <span class="d-block fw-semibold text-start">
                      <span class="text-dark fw-bold d-block fs-3"><b>AGENDA FIXA</b></span>
                      <span class="text-muted fw-semibold fs-6">
                          <b>Você tem horários de atendimento fixos e com poucas alterações?</b>
                        <br>
                        Neste caso, utilize a opção <b>Configurar Agenda Fixa</b>. Com ela você define de uma só vez os dias e
                        horários de atendimento por um longo período, sem precisar ficar configurando toda semana.
                      </span>
                  </span>
                </label>
              </div>

              <div class="d-flex flex-stack pt-10">
                <div class="me-2">
                  <button type="button" @click="previvousStep" class="btn btn-lg btn-light-primary me-3">
                    <span class="svg-icon svg-icon-3 me-1">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
												<path
                            d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                            fill="currentColor"></path>
											</svg>
										</span>
                    Voltar
                  </button>
                </div>
                <div>
                  <button type="button" @click="nextStep" class="btn btn-lg btn-primary">Próximo
                    <span class="svg-icon svg-icon-3 ms-1 me-0">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                              fill="currentColor"></rect>
												<path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="currentColor"></path>
											</svg>
										</span>
                  </button>
                </div>
              </div>

            </div> -->

            <div class="col-md-12" v-show="currentStep === 'hours-settings'">
              <div class="row text-center">
                <p class="h3">Configuraçōes da agenda</p>
                <br>
              </div>

              <div class="pb-10 pb-lg-12">
                <div class="text-muted fw-semibold fs-4">
                  <small>
                    Preencha os dados abaixo para a agenda
                    {{ formAvailWeek.avail_type === 'VA' ? 'dinâmica' : 'fixa' }}.
                  </small>
                </div>
              </div>

              <div>
                <div class="row" v-show="formAvailWeek.avail_type === 'VA'">
                  <div class="col-md-6">
                    <div class="mb-10">
                      <label for="interval_minutes" class="required form-label">
                        Tempo médio da consulta <br>
                        <small>Em minutos</small>
                      </label>
                      <input type="number" placeholder="Ex. 30" v-model="formAvailWeek.interval_minutes"
                             class="form-control form-control-solid">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-10">
                      <label for="interval_minutes" class="required form-label">
                        Liberação de horário padrão<br>
                        <small>Como será sua agenda na ubbi</small>
                      </label>
                      <select class="form-control form-control-solid" v-model="formAvailWeek.default_release_time_for">
                        <option value="B">Online e presencial</option>
                        <option value="O">Online</option>
                        <option value="F">Presencial</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="row" v-show="formAvailWeek.avail_type === 'FI'">
                  <div class="col-md-12">
                    <div class="mb-10">
                      <label for="kt_tagify_week_day" class="required form-label">Dias da semana que você atende</label>
                      <MultiSelect v-model="formAvailWeek.week_days" placeholder="Dia da semana"
                                   class="form-control form-control-solid d-flex align-items-center"
                                   optionValue="value"
                                   :options="weekDays" optionLabel="name"/>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="mb-10">
                      <label for="kt_tagify_payment_methods" class="required form-label">Formas de recebimento</label>
                      <MultiSelect v-model="formAvailWeek.payment_methods" placeholder="Formas de Recebimento"
                                   class="form-control form-control-solid d-flex align-items-center"
                                   optionValue="id_paymeth"
                                   :options="paymentMethods" optionLabel="paymeth_name"/>
                    </div>
                  </div>

                  <div class="col-md-12">
                    <label class="form-check form-switch form-check-custom form-check-solid">
                      <input class="form-check-input" type="checkbox" v-model="formAvailWeek.avail_online"/>
                      <span class="form-check-label">
                      Atender por teleconsulta?
                      </span>
                    </label>
                    <br>
                  </div>

                  <div class="col-md-4">
                    <div class="mb-10">
                      <label for="interval_minutes" class="required form-label">
                        Tempo médio de atendimento <br>
                        <small>Em minutos</small>
                      </label>
                      <input type="number" placeholder="Ex. 30" v-model="formAvailWeek.interval_minutes"
                             class="form-control form-control-solid">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-10">
                      <label for="time_begin" class="required form-label">Início de atendimento - manhã</label>
                      <input type="text" v-mask="'##:##'" placeholder="08:00" v-model="formAvailWeek.time_begin"
                             class="form-control form-control-solid">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-10">
                      <label for="time_end" class="required form-label">Fim do atendimento - manhã</label>
                      <input type="text" v-mask="'##:##'" placeholder="12:00" v-model="formAvailWeek.time_end"
                             class="form-control form-control-solid">
                    </div>
                  </div>

                  <div class="col-md-4">
                  </div>
                  <div class="col-md-4">
                    <div class="mb-10">
                      <label for="time_begin" class="required form-label">Início de atendimento - tarde</label>
                      <input type="text" v-mask="'##:##'" placeholder="08:00"
                             v-model="formAvailWeek.time_begin_afternoon"
                             class="form-control form-control-solid">
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="mb-10">
                      <label for="time_end" class="required form-label">Fim do atendimento - tarde</label>
                      <input type="text" v-mask="'##:##'" placeholder="12:00" v-model="formAvailWeek.time_end_afternoon"
                             class="form-control form-control-solid">
                    </div>
                  </div>

                </div>
              </div>

              <div class="d-flex flex-stack pt-10">
                <div class="me-2">
                  <button type="button" @click="previvousStep" class="btn btn-lg btn-light-primary me-3">
                    <span class="svg-icon svg-icon-3 me-1">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
												<path
                            d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z"
                            fill="currentColor"></path>
											</svg>
										</span>
                    Voltar
                  </button>
                </div>
                <div>
                  <button type="button" @click="nextStep" class="btn btn-lg btn-primary">Salvar e Avançar
                    <span class="svg-icon svg-icon-3 ms-1 me-0">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.5" x="18" y="13" width="13" height="2" rx="1" transform="rotate(-180 18 13)"
                              fill="currentColor"></rect>
												<path
                            d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                            fill="currentColor"></path>
											</svg>
										</span>
                  </button>
                </div>
              </div>

            </div>

            <!--
            <div class="col-md-12" v-show="currentStep === 'card-admin'">
              <div class="row text-center">
                <p class="h3">Administradora de cartão de crédito</p>
                <br>
              </div>

              <div class="pb-10 pb-lg-12">
                <div class="text-dark fw-semibold fs-4">
                  <small>
                    Conta para recebimento via em Cartão de Crédito
                  </small>
                </div>
              </div>

              <div class="fv-row mb-10">
                <div class="row">
                  <div class="col-md-12">

                    <p>
                      Os pagamentos da Ubbimed são realizados por meio de nosso parceiro,
                      o Mercado Pago, e são liberados após a realização da consulta.
                      <br>
                      Agora precisamos crias e vincular sua conta do Mercado Pago à sua conta Ubbimed.
                    </p>

                    <p>
                      Caso já tenha uma conta no Mercado Pago,
                      vá direto na opção <b>Vincular Minha Conta Mercado Pago</b><br>
                      Caso ainda não tenha, siga os passos abaixo:
                    </p>

                    <p>
                      <b>1. Criar conta no Mercado Pago:</b> <br>
                      <small>Você será direcionado ao Mercado Pago para criar sua conta.</small>
                    </p>

                    <div class="row">
                      <div class="col-md-12 text-center">
                        <a href="https://www.mercadopago.com.br" target="_blank" class="btn btn-secondary">
                          Criar conta <i class="bi bi-box-arrow-up-right"></i>
                        </a>
                      </div>
                    </div>
                    <br><br>

                    <p>
                      <b>2. Vincular Minha Conta Mercado Pago:</b> <br>
                      <small>A vinculação permitirá que você receba por suas consultas e serviços no Mercado Pago</small>
                    </p>

                    <div class="row">
                      <div class="col-md-12 text-center">
                        <button class="btn btn-primary" @click="redirectToAuthorizationUrl()">
                          Vincular Minha Conta
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-stack pt-10">
                <div class="me-2">
                  <button type="button" @click="previvousStep" class="btn btn-lg btn-light-primary me-3">
                    <span class="svg-icon svg-icon-3 me-1">
											<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
												<rect opacity="0.5" x="6" y="11" width="13" height="2" rx="1" fill="currentColor"></rect>
												<path d="M8.56569 11.4343L12.75 7.25C13.1642 6.83579 13.1642 6.16421 12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75L5.70711 11.2929C5.31658 11.6834 5.31658 12.3166 5.70711 12.7071L11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25C13.1642 17.8358 13.1642 17.1642 12.75 16.75L8.56569 12.5657C8.25327 12.2533 8.25327 11.7467 8.56569 11.4343Z" fill="currentColor"></path>
											</svg>
										</span>
                    Voltar
                  </button>
                </div>
                <div>

                </div>
              </div>

            </div> -->

            <div class="col-md-12" v-show="currentStep === 'success'">

              <div class="row text-center">
                <p class="h3" style="z-index: 1">Parabéns, o seu cadastro foi realizado <br>com sucesso!</p>
                <div style="margin-top: -50px">
                  <video class="" width="300" height="300" autoplay playsinline muted>
                    <source :src="require('@/assets/mp4/95088-success.mp4')" type="video/mp4"/>
                  </video>
                </div>
              </div>

              <!-- <p>
                Sua conta do Mercado Pago foi vinculada com sucesso. <br>
                Após validarmos os seus documentos, você poderá realizar os atendimentos e
                receber diretamente na sua conta do Mercado Pago.
              </p> -->

              <br>

              <b>
                Quer reduzir suas faltas e aumentar seu número de pacientes? <br>
                Permita que seus pacientes agendem consultas com você via WhatsApp, 24 horas por dia, 7 dias por semana.
              </b>
              <br> <br>
              <p>Vamos configurar seu WhatsApp Bussiness e vincular à sua página da Ubbimed.</p>

              <ol>
                <li>
                  Abra o seu Aplicativo do WhatsApp Bussiness
                </li>
                <li>
                  Clique no ícone <i class="bi bi-three-dots-vertical"></i> e depois em <b>Configurações</b>
                </li>
                <li>
                  Clique em <b>Ferramentas Comerciais</b>
                </li>
                <li>
                  <b>Mensagem de ausência</b>
                </li>
                <li>
                  <p>Copie e cole a mensagem abaixo no campo de resposta automatica.</p>
                  <textarea rows="5" class="form-control" v-model="messageWithWhatsAppLink"></textarea>
                  <br>
                  <button class="btn btn-primary" @click="copyMessageWithLink">
                    Copiar mensagem
                  </button>
                  <br>
                </li>
                <li>
                  Em <b>Horário Programado</b> selecione a opção <b>Enviar Sempre</b>.
                  <br>
                  Adicione a <b>data de Início</b> e <b>data de Término</b>.
                  <br>
                  Em <b>Destinatário</b> selecione a opção <b>Todos</b>
                </li>
              </ol>

              <p>Pronto! Agora sua agenda ficará visível para os seus pacientes 24h por dia, 7 dias por semana 😉</p>

              <div class="row">
                <div class="col-md-12 text-center" v-if="formAvailWeek.avail_type === 'FI'">
                  <a class="btn btn-primary" href="/availabilities" aria-label="Fechar" @click="setPlace">
                    Configurar minha disponibilidade
                  </a>
                </div>
                <div class="col-md-12 text-center" v-else>
                  <a class="btn btn-primary" href="/release-times" aria-label="Fechar" @click="setPlace">
                    Librar horários
                  </a>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primevue/resources/themes/bootstrap4-light-blue/theme.css';
import 'primeicons/primeicons.css';

import 'dropzone-vue/dist/dropzone-vue.common.css';
import http from "../helpers/http";
import DropZone from 'dropzone-vue';
import Config from "../config";
import store from "../store";
import {mask} from "vue-the-mask";
import Alert from "@/helpers/alert";
import strMask from "@/helpers/strMask";
import MultiSelect from 'primevue/multiselect';
import Utils from "@/helpers/utils";

export default {
  name: "ModalWelcome",
  components: {
    DropZone,
    MultiSelect,
  },
  directives: {
    mask,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    initialStep: {
      type: String,
      default: 'initial',
    },
  },
  data() {
    return {
      currentStep: this.initialStep,
      tmpPlaceData: '',
      messageWithWhatsAppLink: `Olá,
Agradecemos pelo contato.
Agora você pode agendar sua consulta direto do seu celular.
Para isso, clique no link abaixo:
      `,
      documentTypes: [],
      weekDays: [],
      paymentMethods: [],
      existingDocuments: {},
      kind: [],
      headers: {
        'ubbi-app': Config.app_name,
        'ubbi-app-token': Config.app_token,
        'Authorization': `Bearer ${store.state.auth.token}`,
      },
      formAvailWeek: {
        default_release_time_for: 'B',
        avail_online: true,
        avail_type: 'VA',
        interval_minutes: 30,
        time_begin: '08:00',
        time_end: '12:00',
        time_begin_afternoon: '13:00',
        time_end_afternoon: '18:00',
        payment_methods: [],
        week_days: [],
      },
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    professional() {
      return this.$store.state.auth.professionals ? this.$store.state.auth.professionals[0] : {};
    },
    endpointUpload() {
      return Config.base_url + `/professionals/${this.professional?.id_prof}/documents/upload?id_kind=${this.kind.id_kind}`;
    },
  },
  async mounted() {
    if (this.showModal) {
      await this.$store.dispatch('auth/loadProfessionals');
      await new Promise(r => setTimeout(r, 1000));
      this.loadWeekDays();
      this.getDocumentTypes();
      await this.loadPaymentMethods();
      await this.loadExistingDocuments();

      bootstrap.Modal.getOrCreateInstance(document.getElementById('modal_welcome')).toggle({
        backdrop: 'static', keyboard: false
      });
    }
  },
  methods: {
    completeWelcomeModal() {

      let data = {
        id_prof: this.professional?.id_prof,
        place_welcome: true,
      };

      http.post(`/places/${this.place.id_place}/welcome`, data).then((response) => {
        this.currentStep = 'success';
        this.tmpPlaceData = response.data;
      }).catch(e => {
        Alert.toastError(e.response.data);
      });
    },
    async setPlace() {
      this.$store.commit('auth/setPlace', this.tmpPlaceData);
      let responseProfessionals = this.$store.dispatch('auth/loadProfessionals');
      let responsePermissions = this.$store.dispatch('auth/loadPermissions');
      await Promise.all([responseProfessionals, responsePermissions]);
    },
    copyMessageWithLink() {
      http.get(`/places/${this.place.id_place}/professionals/${this.professional.id_prof}/links/profile`).then((response) => {
        let link = response.data;
        let message = this.messageWithWhatsAppLink + link;
        navigator.clipboard.writeText(message);

        Alert.toastInfo("Mensagem copiada com sucesso.")
      });
    },
    previvousStep() {
      if (this.currentStep === 'initial' || this.currentStep === 'success') {
        return;
      }

     // if (this.currentStep === 'hours') {
      if (this.currentStep === 'hours-settings') {
        this.currentStep = 'documents';
        return;
      }

      /*if (this.currentStep === 'hours-settings') {
        this.currentStep = 'hours';
        return;
      }*/

      if (this.currentStep === 'documents') {
        this.currentStep = 'initial';
        return;
      }

      /*if (this.currentStep === 'card-admin') {
        this.currentStep = 'hours-settings';
      }*/
    },
    nextStep() {
      if (this.currentStep === 'initial') {
        this.currentStep = 'documents';
        return;
      }
      if (this.currentStep === 'documents') {
        //this.currentStep = 'hours';
        this.currentStep = 'hours-settings';
        return;
      }
      /*if (this.currentStep === 'hours') {
        this.currentStep = 'hours-settings';
        return;
      }*/
      if (this.currentStep === 'hours-settings') {
        this.saveAvailability().then(() => {
          //this.currentStep = 'card-admin';
          this.completeWelcomeModal();
        });
      }
    },
    saveAvailability() {
      if (this.formAvailWeek.avail_type === 'FI' && this.formAvailWeek.payment_methods.length <= 0) {
        Alert.toastError('Informe as formas de recebimento.');

        return;
      }

      if (this.formAvailWeek.avail_type === 'FI' && this.formAvailWeek.week_days.length <= 0) {
        Alert.toastError('Informe os dias da semana que você atende.');

        return;
      }

      return http.post(`/places/${this.place.id_place}/professionals/${this.professional.id_prof}/availabilities/initital`, this.formAvailWeek).then((r) => {
        //Alert.toastSuccess("Configuração da agenda salva com sucesso.");

        return r;
      }).catch((e) => {
        Alert.toastError(e.response.data);
        return null;
      });
    },
    getDocumentTypes() {
      this.getKinds().then(() => {
        let params = {params: {id_kind: this.kind.id_kind}}
        http.get('/document-types', params).then((response) => this.documentTypes = response.data);
      });
    },
    getKinds() {
      return http.get(`/professionals/${this.professional.id_prof}/kinds`).then((response) => {
        this.kind = response.data[0];

        return response;
      });
    },
    loadPaymentMethods() {
      return http.get(`/places/${this.place.id_place}/payment-methods`).then((response) => {
        this.paymentMethods = response.data.map((paymentMethod) => {
          paymentMethod.value = paymentMethod.id_paymeth;

          return paymentMethod;
        });

        return response;
      });
    },
    loadWeekDays() {
      http.get(`/week-days`).then((response) => {
        this.weekDays = response.data.map((weekDay) => {
          weekDay.value = weekDay.number;

          return weekDay;
        });

        return response;
      });
    },
    loadExistingDocuments() {
      http.get(`/professionals/${this.professional?.id_prof}/documents`).then((response) => {
        this.existingDocuments = this.splitDocumentsByType(response.data);
        return response;
      });
    },
    splitDocumentsByType(documents) {
      const documentsByType = {};
      documents.forEach((document) => {
        if (!documentsByType[document.id_doc_type]) {
          documentsByType[document.id_doc_type] = [];
        }
        documentsByType[document.id_doc_type].push(document);
      });
      return documentsByType;
    },
    maskIt(value, mask) {
      return strMask.it(value, mask);
    },
    redirectToAuthorizationUrl() {
      http.get(`/places/${this.place.id_place}/card-administrators`).then((response) => {
        let cardAdministrator = response.data[0];

        http.get(`/places/${this.place.id_place}/card-administrators/${cardAdministrator.id_card_adm}/url-authorization`).then((response) => {
          let urlAuthorization = response.data;
          navigator.clipboard.writeText(urlAuthorization);

          Alert.toastInfo("Link copiado com sucesso.")
          window.location.href = urlAuthorization;
        });
      });
    },
    deleteFile(id_doc_type, id_prof_document) {
      http.delete(`/professionals/${this.professional?.id_prof}/documents/${id_prof_document}`).then((response) => {
        this.existingDocuments[id_doc_type] = this.existingDocuments[id_doc_type].filter(item => item.id_prof_document !== id_prof_document);
        Alert.toastSuccess("Arquivo excluído com sucesso.")
      }).catch(e => {
        Alert.toastError(e.response.data.message ?? e.response.data);
      });
    },
    onUploaded(file) {
      this.loadExistingDocuments();
    },
    onErrorUpload() {
      Alert.toastError('Erro ao enviar arquivo!');
    },
    getFileSvg(mimeType) {
      return Utils.getFileSvg(mimeType);
    },
  }
}
</script>

<style scoped>
.document-container {
  position: relative;
  display: inline-block;
}

.delete-button {
  position: absolute;
  top: 5px;
  right: 10px;
  padding: 0px;
  background-color: transparent;
  border: none;
}
</style>