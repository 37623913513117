<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid justify-content-center bg-ubbi" style="background-color: #780f5a;">
      <div class="d-flex flex-column flex-center text-center p-10">
        <div class="card card-flush w-lg-650px py-5">
          <div class="card-body py-15 py-lg-20">
            <div v-if="isRejected">
              <img alt="Logo" :src="require('@/assets/images/undraw_medicine_b-1-ol.svg')" class="h-200px">
              <br><br><br>
              <h1>Seu convite para o local "{{infos.place_name}}" foi cancelado!</h1>
              <br><br><br>
              <a class="btn btn-primary" :href="siteUrl">
                Ir para o site
              </a>
            </div>
            <div v-else-if="isAccepted">
              <img alt="Logo" :src="require('@/assets/images/undraw_medicine_b-1-ol.svg')" class="h-200px">
              <br><br><br>
              <h1>Seu convite para o local "{{infos.place_name}}" foi aceito!</h1>
              <br><br><br>
              <router-link class="btn btn-primary" to="/">
                Acessar
              </router-link>
            </div>
            <div v-else-if="isInvalid">
              <img alt="Logo" :src="require('@/assets/images/thinking_face-cuate.svg')" class="h-200px">
              <br>
              <h1>{{ invalidMessage }}</h1>
              <br><br>
              <a class="btn btn-primary" :href="siteUrl">
                Ir para o site
              </a>
            </div>
            <div v-else>
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-13">
                    <router-link to="/" class="">
                      <img v-if="isAccept" alt="Logo" :src="require('@/assets/images/undraw_business_deal_re_up4u.svg')" class="h-150px">
                      <img v-else alt="Logo" :src="require('@/assets/images/undraw_cancel_re_pkdm.svg')" class="h-150px">
                    </router-link>
                  </div>
                </div>
              </div>
              <h1 class="fw-bolder text-gray-900 mb-7">Olá {{infos.user_name}}</h1>

              <div class="fw-semibold fs-6 text-gray-500 mb-7">
                Você tem certeza que deseja {{labelAction}} o convite <br>
                para acessar o(a) {{infos.place_name}} na plataforma da Ubbimed?
              </div>

              <div v-if="isAccept">
                <div class="row" v-if="infos.user_name">
                  <div class="col-md-8 offset-lg-2">
                    <div class="alert alert-danger" v-show="messageErrorLogin" role="alert">
                      {{ messageErrorLogin }}
                    </div>

                    <br>
                    <div class="fv-row mb-10 fv-plugins-icon-container">

                      <div class="d-flex flex-stack mb-2">
                        <div class="w-100 d-inline-flex mb-3 px-2 py-3 fw-semibold text-muted bg-secondary bg-opacity-10 border border-secondary border-opacity-10 rounded-2">
                          <i class="bi bi-person-circle fa-1x me-2 mt-1"></i> {{auth.username}}
                        </div>
                      </div>

                      <div class="d-flex flex-stack mb-2">
                        <label class="form-label fw-bolder text-dark fs-6 mb-0">
                          Para continuar, digite sua senha <span class="text-danger">*</span>
                        </label>
                      </div>
                      <input class="form-control form-control-lg form-control-solid"
                             v-model="auth.password" required
                             type="password" name="password" autocomplete="off" placeholder="Digite sua senha">
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="fv-row mb-10 fv-plugins-icon-container">

                    <div class="alert alert-danger" v-show="messageErrorLogin" role="alert">
                      {{ messageErrorLogin }}
                    </div>

                    <div class="d-flex flex-stack mb-2">
                      <div class="w-100 d-inline-flex mb-3 px-2 py-3 fw-semibold text-muted bg-secondary bg-opacity-10 border border-secondary border-opacity-10 rounded-2">
                        <i class="bi bi-person-circle fa-1x me-2 mt-1"></i> {{auth.user_email}}
                      </div>
                    </div>

                    <div>
                      <div class="d-flex flex-stack mb-2">
                        <label class="form-label fw-bolder text-dark fs-6 mb-0">
                          Nome <span class="text-danger">*</span>
                        </label>
                      </div>
                      <input class="form-control form-control-lg form-control-solid"
                             v-model="auth.user_name" required type="text" name="user_name"
                             autocomplete="off" placeholder="Digite o seu nome">
                    </div>
                    <br>
                    <div>
                      <div class="d-flex flex-stack mb-2">
                        <label class="form-label fw-bolder text-dark fs-6 mb-0">
                          Senha <span class="text-danger">*</span>
                        </label>
                      </div>
                      <input class="form-control form-control-lg form-control-solid"
                             v-model="auth.user_password" required type="password" name="password"
                             autocomplete="off" placeholder="Digite sua senha">
                    </div>
                  </div>

                </div>
              </div>

              <button class="btn" :class="isAccept ? 'btn-primary' : 'btn-danger'"
                      @click="handdlerSubmit">
                Sim, {{labelAction}} convite
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import http from "../../helpers/http";
import Alert from "../../helpers/alert";
import Config from "../../config";

export default {
  name: "InviteUserAction",
  data() {
    return {
      isInvalid: false,
      invalidMessage: '',
      messageErrorLogin: '',
      isRejected: false,
      isAccepted: false,
      infos: {},
      place: {},
      auth: {
        user_name: '',
        user_password: '',
        user_email: '',

        username: '',
        password: '',
      },
      siteUrl: Config.site_url_prof,
    }
  },
  mounted() {
    if (this.$store.getters['auth/isAuth'] && this.isAccept) {
      this.$store.dispatch("auth/logout");
    }
    this.findInfosToken();
  },
  computed: {
    inviteId() {
      return this.$route.params.inviteId;
    },
    inviteToken() {
      return this.$route.query.token;
    },
    action() {
      return this.$route.params.action;
    },
    placeId() {
      return this.$route.params.placeId;
    },
    isAccept() {
      return this.action === 'accept';
    },
    labelAction() {
      return this.isAccept ? 'aceitar' : 'recusar';
    },
  },
  methods: {
    accept() {
      let params = {'token': this.inviteToken};
      return http.patch(`/places/${this.placeId}/invites/${this.inviteId}/accept`, params).then(() => {
        Alert.toastSuccess('Convite recusado com sucesso.');

        this.isAccepted = true;
        this.isRejected = false;
        this.isInvalid = false;
      }).catch((e) => {
        Alert.toastError(e.response.data);
        this.isInvalid = true;
        this.invalidMessage = e.response.data;
      });
    },
    login() {
      this.messageErrorLogin = null;
      return this.$store.dispatch('auth/login', this.auth).then(async () => {

        await this.accept();
      }).catch((error) => {
        this.messageErrorLogin = error.response.data.message ?? error.response.data;
      });
    },
    register() {
      return http.post(`/employees/register`, this.auth).then(async (response) => {
        this.$store.commit('auth/setUser', response.data.user);
        this.$store.commit('auth/setToken', response.data.token);

        await this.accept();
        await this.$store.dispatch('auth/loadPlaces', response.data.user);
      }).catch((error) => {
        this.messageErrorLogin = error.response.data.message ?? error.response.data;
      });
    },
    reject() {
      let params = {'token': this.inviteToken};
      http.patch(`/places/${this.placeId}/invites/${this.inviteId}/reject`, params).then(() => {
        Alert.toastSuccess('Convite recusado com sucesso.');

        this.isRejected = true;
        this.isAccepted = false;
        this.isInvalid = false;
      }).catch((e) => {
        Alert.toastError(e.response.data);
      });
    },
    findInfosToken() {
      let params = {params: {'token': this.inviteToken}};
      http.get(`/places/${this.placeId}/invites/${this.inviteId}/infos`, params).then((response) => {

        this.infos = response.data;
        this.auth.username = this.infos.user_email;
        this.auth.user_email = this.infos.user_email;
        this.isRejected = false;
        this.isAccepted = false;
      }).catch((e) => {
        this.invalidMessage = e.response.data;
        this.isInvalid = true;
      });
    },
    handdlerSubmit() {
      if (!this.isAccept) {
        return this.reject();
      }

      if (this.infos.user_name) {
        return this.login();
      }

      return this.register();
    }
  },
}
</script>

<style scoped>

</style>