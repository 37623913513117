<template>
  <div class="card">
    <div class="card-header">
      <h2 class="card-title fw-bolder"></h2>
      <div class="card-toolbar">
        <div class="">
          <button class="btn btn-flex btn-primary m-1 p-3" data-bs-toggle="modal"
                  v-if="hasPermission('Store Availability Block')"
                  title="Nova disponibilidade" data-bs-target="#modal_store_unavailability">
            <i class="bi bi-plus-lg"></i> Adicionar
          </button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="table-responsive">
        <table class="table gy-5">
          <thead>
          <tr class="fw-bold fs-6 text-muted">
            <th>Descrição</th>
            <th>Período</th>
            <th>Horário</th>
            <th>Ações</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="unAvailability in unAvailabilities">
            <td>{{ unAvailability.dsc_avail_block }}</td>
            <td>
              {{ formatDate(unAvailability.date_begin) }} -
              {{ formatDate(unAvailability.date_end) }}
            </td>
            <td>{{ maskIt(unAvailability.time_begin, '##:##') }} - {{ maskIt(unAvailability.time_end, '##:##') }}</td>
            <td>
              <button type="button" class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                      v-if="hasPermission('Update Availability Block')"
                      data-bs-toggle="modal"
                      data-bs-target="#modal_edit_unavailability"
                      @click="onClickBtnEdit(unAvailability)"
                      title="Editar Indisponibilidade">
                <i class="bi bi-pencil-square"></i>
              </button>
              <button class="btn btn-icon btn-bg-light btn-active-color-primary me-1"
                      v-if="hasPermission('Delete Availability Block')"
                      @click="deleteUnAvailability(unAvailability.id_avail_block)"
                      data-bs-toggle="tooltip" title="Editar Indisponibilidade">
                <i class="bi bi-trash"></i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="modal_edit_unavailability">
    <div class="modal-dialog modal-lg">
      <form method="post" @submit.prevent="submitFormEdit">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Editar Disponibilidade</h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-10">
                  <label for="dsc_avail_block_edit" class="required form-label">Nome</label>
                  <input type="text" class="form-control form-control-solid"
                         id="dsc_avail_block_edit" max="100" v-model="formEdit.dsc_avail_block">
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="mb-10">
                  <label for="date_begin_edit" class="required form-label">Data de início</label>
                  <input type="text" class="form-control form-control-solid" id="date_begin_edit"
                         v-mask="'##/##/####'" placeholder="DD/MM/YYYY" v-model="formEdit.date_begin"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-10">
                  <label for="date_end_edit" class="required form-label">Data de fim</label>
                  <input type="text" class="form-control form-control-solid" id="date_end_edit"
                         v-mask="'##/##/####'" placeholder="DD/MM/YYYY" v-model="formEdit.date_end"/>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="mb-10">
                  <label for="time_begin_edit" class="required form-label">Hora de início</label>
                  <input type="text" class="form-control form-control-solid" id="time_begin_edit"
                         v-mask="'##:##'" placeholder="HH:MM" v-model="formEdit.time_begin"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-10">
                  <label for="time_end_store" class="required form-label">Hora de término</label>
                  <input type="text" class="form-control form-control-solid" id="time_end_edit"
                         v-mask="'##:##'" placeholder="HH:MM" v-model="formEdit.time_end"/>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">Salvar</button>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="modal fade" tabindex="-1" id="modal_store_unavailability">
    <div class="modal-dialog modal-lg">
      <form method="post" @submit.prevent="submitFormStore">
        <div class="modal-content modal-lg">
          <div class="modal-header">
            <h5 class="modal-title">Cadastrar Indisponibilidade </h5>
            <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
              <i class="bi bi-x-lg"></i>
            </div>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-10">
                  <label for="dsc_avail_block_store" class="required form-label">Nome</label>
                  <input type="text" class="form-control form-control-solid"
                         id="dsc_avail_block_store" max="100" v-model="formStore.dsc_avail_block">
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="mb-10">
                  <label for="date_begin_store" class="required form-label">Data de início</label>
                  <input type="text" class="form-control form-control-solid" id="date_begin_store"
                         v-mask="'##/##/####'" placeholder="DD/MM/YYYY" v-model="formStore.date_begin"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-10">
                  <label for="date_end_store" class="required form-label">Data de fim</label>
                  <input type="text" class="form-control form-control-solid" id="date_end_store"
                         v-mask="'##/##/####'" placeholder="DD/MM/YYYY" v-model="formStore.date_end"/>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <div class="mb-10">
                  <label for="time_begin_store" class="required form-label">Hora de início</label>
                  <input type="text" class="form-control form-control-solid" id="time_begin_store"
                         v-mask="'##:##'" placeholder="HH:MM" v-model="formStore.time_begin"/>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-10">
                  <label for="time_end_store" class="required form-label">Hora de término</label>
                  <input type="text" class="form-control form-control-solid" id="time_end_store"
                         v-mask="'##:##'" placeholder="HH:MM" v-model="formStore.time_end"/>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
            <button type="submit" class="btn btn-primary">Cadastrar</button>
          </div>
        </div>
      </form>
    </div>
  </div>

</template>

<script>
import {mask} from "vue-the-mask";
import strMask from "../../../../helpers/strMask";
import http from "../../../../helpers/http";
import Alert from "../../../../helpers/alert";
import Permission from "../../../../helpers/permission";

export default {
  name: "Unavailability",
  props: {
    currentProfessionalId: '',
  },
  directives: {
    mask,
  },
  data() {
    return {
      formEdit: {
        dsc_avail_block: '',
        date_begin: '',
        date_end: '',
        time_begin: '',
        time_end: '',
        id_prof: '',
        id_avail_block: '',
      },
      formStore: {
        dsc_avail_block: '',
        date_begin: '',
        date_end: '',
        time_begin: '',
        time_end: '',
        id_prof: '',
      },
      unAvailabilities: [],
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  watch:{
    currentProfessionalId(newvalue, oldvalue) {
      if (newvalue)
        this.loadUnAvailabilities();
    }
  },
  methods: {
    date: moment,
    hasPermission(permission) {
      return Permission.has(permission);
    },
    maskIt(value, mask) {
      return strMask.it(value, mask);
    },
    formatDate(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    loadUnAvailabilities() {
      http.get(`/places/${this.place.id_place}/professionals/${this.currentProfessionalId}/availabilities-blocks`).then((response) => {
        this.unAvailabilities = response.data;
      });
    },
    deleteUnAvailability(unAvailabilityId) {
      Swal.fire({
        title: 'Heeyy',
        text: "Tem certeza que deseja remover a indisponibilidade?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, remover!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: "btn btn-danger",
          cancelButton: 'btn btn-secundary'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          http.delete(`/places/${this.place.id_place}/professionals/${this.currentProfessionalId}/availabilities-blocks/${unAvailabilityId}`).then((_) => {
            this.loadUnAvailabilities();
            Alert.toastSuccess("Indisponibilidade Removida com sucesso.");
          }).catch((e) => {
            Alert.toastError(e.response.data);
          });
        }
      });
    },
    submitFormStore() {
      this.formStore.date_begin = moment(this.formStore.date_begin, 'DD/MM/YYYY', true).format('YYYY-MM-DD');
      this.formStore.date_end = moment(this.formStore.date_end, 'DD/MM/YYYY', true).format('YYYY-MM-DD');
      this.formStore.id_prof = this.currentProfessionalId;

      http.post(`/places/${this.place.id_place}/professionals/${this.currentProfessionalId}/availabilities-blocks`, this.formStore).then(_ => {
        this.loadUnAvailabilities();
        Alert.toastSuccess("Indisponibilidade cadastrada com sucesso.");

        this.formStore = {
          date_begin: '',
          date_end: '',
          time_begin: '',
          time_end: '',
          id_prof: '',
        };

       bootstrap.Modal.getInstance(document.getElementById('modal_store_unavailability')).toggle();
      }).catch(e => {
        Alert.toastError(e.response.data);
      });
    },
    submitFormEdit() {
      http.put(`/places/${this.place.id_place}/professionals/${this.currentProfessionalId}/availabilities-blocks/${this.formEdit.id_avail_block}`, this.formEdit).then(() => {
        this.loadUnAvailabilities();
        Alert.toastSuccess("Disponibilidade atualizada com sucesso.");

        bootstrap.Modal.getInstance(document.getElementById('modal_edit_unavailability')).toggle();
      });
    },
    onClickBtnEdit(unavailability) {
      this.formEdit.date_begin = moment(unavailability.date_begin, 'YYYY-MM-DD').format('DD/MM/YYYY');
      this.formEdit.date_end = moment(unavailability.date_end, 'YYYY-MM-DD').format('DD/MM/YYYY');
      this.formEdit.time_begin = unavailability.time_begin;
      this.formEdit.time_end = unavailability.time_end;
      this.formEdit.id_prof = unavailability.id_prof;
      this.formEdit.dsc_avail_block = unavailability.dsc_avail_block;
      this.formEdit.id_avail_block = unavailability.id_avail_block;
    }
  }
}
</script>

<style scoped>

</style>