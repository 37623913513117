<template>
  <div class="modal fade" tabindex="-1" id="modal_search_schedules">
    <div class="modal-dialog modal-lg">
      <div class="modal-content" v-show="!modalScheduleIsOpen">
        <div class="modal-header">
          <h5 class="modal-title">Buscar agendamento</h5>
          <div class="btn btn-icon btn-sm btn-active-light-primary ms-2" data-bs-dismiss="modal" aria-label="Fechar">
            <i class="bi bi-x-lg"></i>
          </div>
        </div>
        <div class="modal-body">
          <form @submit.prevent="searchSchedules">
            <div class="row">
              <div class="col-xl-6">
                <label class="fs-6 fw-bold mb-2">Status</label>
                <CalendarInputStatus v-model="form.sched_status"/>
              </div>
              <div class="col-xl-6">
                <div class="row">
                  <div class="col-md-6">
                    <label class="fs-6 fw-bold required mb-2">Data início</label>
                    <input type="text" class="form-control form-control-solid" id="date_begin_edit" required
                           v-mask="'##/##/####'" placeholder="DD/MM/YYYY" v-model="form.date_start"/>
                  </div>
                  <div class="col-md-6">
                    <label class="fs-6 fw-bold required mb-2">Data fim</label>
                    <input type="text" class="form-control form-control-solid" id="date_begin_edit" required
                           v-mask="'##/##/####'" placeholder="DD/MM/YYYY" v-model="form.date_end"/>
                  </div>
                </div>
              </div>
              <div class="col-xl-6">
                <label class="fs-6 fw-bold mb-2" for="id_prof">Profissional</label>
                <DropdownUbbi :options="professionals"
                              :filter="true"
                              :showClear="true"
                              appendTo="#modal_search_schedules"
                              v-model="form.id_prof"
                              optionLabel="prof_name"
                              optionValue="id_prof"
                              @change="onChangeProfessional()"
                              empty-message="Nenhuma opção"
                              empty-filter-message="Nenhuma opção encontrada"
                              id="id_prof"
                              class="form-control form-control-solid form-control-sm w-full md:w-14rem"
                              placeholder="Profissional"
                              style="display: flex;"
                />
              </div>
              <div class="col-md-3">
                <div class="fv-row">
                  <label class="fs-6 fw-bold mb-2">Especialidade</label>
                  <DropdownUbbi :options="specialties"
                            :filter="true" :showClear="true"
                            appendTo="#modal_search_schedules"
                            v-model="form.id_spec"
                            optionLabel="spec_name"
                            optionValue="id_spec"
                            empty-message="Nenhuma opção"
                            empty-filter-message="Nenhuma opção encontrada"
                            id="id_spec"
                            class="form-control form-control-solid form-control-sm w-full md:w-14rem"
                            placeholder="Especialidades"
                            style="display: flex;"
                  />
                </div>
              </div>
              <div class="col-xl-3">
                <label class="fs-6 fw-bold mb-2">Forma de recebimento</label>
                <DropdownUbbi :options="paymentMethods"
                              v-model="form.id_paymeth"
                              optionLabel="paymeth_name"
                              optionValue="id_paymeth"
                              empty-message="Nenhuma opção"
                              empty-filter-message="Nenhuma opção encontrada"
                              id="id_paymeth"
                              appendTo="#modal_search_schedules"
                              class="form-control form-control-solid form-control-sm w-full md:w-14rem"
                              placeholder="Forma de recebimento"
                              style="display: flex;"
                />
              </div>
              <div class="col-xl-6">
                <label class="fs-6 fw-bold mb-2">Paciente</label>
                <SearchPatient
                  :key="uniqueKey"
                  v-model="form.patient_name"
                  @onInput="onClickSearchedPatient"
                  @onClick="onClickSearchedPatient"
                />
              </div>
              <div class="col-xl-6">
                <label class="fs-6 fw-bold mb-2">Convênio</label>
                <DropdownUbbi :options="insurances"
                              :filter="true"
                              appendTo="#modal_search_schedules"
                              v-model="form.id_insurance"
                              optionLabel="insurance_name"
                              optionValue="id_insurance"
                              :filter-fields="['insurance_name', 'trading_name']"
                              empty-message="Nenhuma opção"
                              empty-filter-message="Nenhuma opção encontrada"
                              id="id_insurance"
                              class="form-control form-control-solid form-control-sm w-full md:w-14rem"
                              placeholder="Convênio"
                              style="display: flex;"
                >
                  <template #option="slotProps">
                    <div class="flex align-items-center">
                      <div v-if="slotProps.option.trading_name">
                        {{ slotProps.option.trading_name }}
                        -
                        {{ slotProps.option.insurance_name }}
                      </div>
                      <div v-else>
                        {{ slotProps.option.insurance_name }}
                      </div>
                    </div>
                  </template>
                </DropdownUbbi>
              </div>
              <div class="col-xl-3">
                <label class="fs-6 fw-bold mb-2">Confirmação enviada?</label>
                <select v-model="form.sent_confirmation" class="form-control form-control-solid">
                  <option :value="null">Todos</option>
                  <option value="SENT">Sim</option>
                  <option value="NOT_SENT">Não</option>
                </select>
              </div>

            </div>

            <br>
            <div class="row">
              <div class="col-md-12 text-end">
                <button type="submit" class="btn btn-primary">
                  Pesquisar
                </button>
              </div>
            </div>
          </form>
          <hr>
          <div class="row">
            <div class="col-md-12">
              <DataTable v-model:filters="filters" :value="schedules" :rowsPerPageOptions="[5, 10, 20, 50]"
                         paginator :rows="10" dataKey="id" filterDisplay="row" :loading="loading" removableSort
                         :globalFilterFields="[
                         'schedule_date_time', 'professional.prof_name', 'payment_method.paymeth_name',
                         'insurance.insurance_name', 'patient.patient_name', 'patient.patient_phone', 'status']">

                <template #header>
                  <div class="row justify-content-end">
                    <div class="col-md-5">
                      <div class="d-flex">
                        <button class="btn btn-sm btn-primary me-2" @click="exportSchedules()">Exportar</button>
                        <input v-model="filters['global'].value" type="text" class="form-control form-control-sm" placeholder="Pesquisar na tabela">
                      </div>
                    </div>
                  </div>
                </template>
                <template #empty class="text-center">
                  <span class="text-center">Nenhum agendamento encontrado</span>
                </template>
                <template #loading class="text-center">
                  <span class="text-center">Carregando, aguarde </span>
                </template>

                <Column header="Data/Hora">
                  <template #body="slotProps">
                    <a href="" @click.prevent="onClickOpenSchedule(slotProps.data.id_sched)">
                      {{this.date(slotProps.data.schedule_date_time).format('DD/MM/YYYY HH:mm')}}
                    </a>
                  </template>
                </Column>
                <Column field="patient.patient_name" sortable header="Paciente"></Column>
                <Column field="patient.patient_phone" header="Celular"></Column>
                <Column header="Status">
                  <template #body="slotProps">
                  <span class="badge" :style="{backgroundColor: slotProps.data.sched_status_color}">
                      {{slotProps.data.status}}
                  </span>
                  </template>
                </Column>
                <Column field="professional.prof_name" sortable header="Profissional"></Column>
                <Column field="payment_method.paymeth_name" sortable header="Forma de pagamento"></Column>
                <Column field="insurance.insurance_name" sortable header="Convênio"></Column>
              </DataTable>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light" data-bs-dismiss="modal">Fechar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchPatient from "../../components/SearchPatient.vue";
import CalendarInputStatus from "./Status.vue";
import DropdownUbbi from "../../components/DropdownUbbi.vue";
import { FilterMatchMode } from 'primevue/api';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import {mask} from "vue-the-mask";
import http from "../../helpers/http";
import Alert from "../../helpers/alert";

export default {
  name: "ModalSearchSchedule",
  components: {DropdownUbbi, CalendarInputStatus, SearchPatient, DataTable, Column},
  props: ['modalScheduleIsOpen'],
  directives: {
    mask
  },
  data() {
    return {
      loading: false,
      uniqueKey: Date.now(),
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        schedule_date_time: { value: null, matchMode: FilterMatchMode.CONTAINS },
        status: { value: null, matchMode: FilterMatchMode.EQUALS },
        'patient.patient_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'professional.prof_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'payment_method.paymeth_name': { value: null, matchMode: FilterMatchMode.CONTAINS },
        'insurance.insurance_name': { value: null, matchMode: FilterMatchMode.CONTAINS }
      },
      form: {
        sent_confirmation: null,
        sched_status: null,
        patient_name: null,
        id_patient: null,
        id_paymeth: null,
        id_insurance: null,
        id_prof: null,
        id_spec: null,
        date_start: null,
        date_end: null,
      },
      specialties: [],
      insurances: [],
      paymentMethods: [],
      schedules: [],
    }
  },
  computed: {
    place() {
      return this.$store.state.auth.place;
    },
    professionals() {
      return this.$store.state.auth.professionals;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  created() {
    this.loadPaymentMethods();
    this.loadSpecialties();
    this.loadInsurances();
    this.loadSpecialties();
  },
  methods: {
    date: moment,
    onClickOpenSchedule(scheduleId) {
      return http.get(`/places/${this.place.id_place}/schedules/${scheduleId}`).then((response) => {
        this.$emit('openModalSchedule', response.data)
        return response;
      });
    },
    async exportSchedules() {
      try {
        const response = await http.get(`/places/${this.place.id_place}/schedules/export`, {
          params: this.form,
          responseType: 'blob'
        });

        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;

        link.setAttribute('download', 'agendamentos.csv');

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        Alert.error(error)
      }
    },
    onChangeProfessional() {
      this.loadSpecialties();
      this.loadPaymentMethods();
      this.loadInsurances();
      this.loadSpecialties();
    },
    loadInsurances() {
      let url = `/places/${this.place.id_place}/insurances`;
      if (this.form.id_prof) {
        url = `/places/${this.place.id_place}/professionals/${this.form.id_prof}/insurances`;
      }
      return http.get(url).then((response) => {
        this.insurances = response.data;
        return response;
      });
    },
    loadPaymentMethods() {
      let url = `/places/${this.place.id_place}/payment-methods`;
      if (this.form.id_prof) {
        url = `/places/${this.place.id_place}/professionals/${this.form.id_prof}/payment-methods`;
      }
      let params = {from_calendar: true}
      return http.get(url, {params: params}).then((response) => {
        this.paymentMethods = response.data;
        return response;
      });
    },
    loadSpecialties() {
      let url = `/places/${this.place.id_place}/specialties`;
      if (this.form.id_prof) {
        url = `/places/${this.place.id_place}/professionals/${this.form.id_prof}/specialties`;
      }

      return http.get(url).then((response) => {
        this.specialties = response.data;
        return response;
      });
    },
    searchSchedules() {
      this.loading = true;
      http.get(`/places/${this.place.id_place}/schedules/search`, {params: this.form})
        .then((response) => this.schedules = response.data)
        .finally(() => this.loading = false);
    },
    onClickSearchedPatient(patient) {
      this.form.id_patient = null;
      this.form.sched_patient_name = null;

      if (patient.id_patient)
        this.form.id_patient = patient.id_patient;
      if (patient.patient_name)
        this.form.sched_patient_name = patient.patient_name;
    },
  }
}
</script>

<style>
.p-paginator > [data-pc-name="rowperpagedropdown"][data-pc-group-section="pagedropdown"] {
  padding-right: 10px
}

#kt_body > div.p-dropdown-panel.p-component.p-ripple-disabled > div > ul {
  padding-left: 0
}

.p-datatable-emptymessage > [data-pc-section="emptymessagecell"] {
  text-align: center;
}

</style>