<template>
  <div v-if="!hasPermission('List Schedule')">Você não tem permissão para acessar esta página!</div>
  <div v-else>

      <Toolbar>
          <template v-slot:actions>
              <div class="d-flex align-items-center flex-wrap">
                  <div class="row">
                      <div class="col-md-12">
                        <button class="btn btn-flex btn-warning btn-sm py-4 px-4 mx-1"
                                title="Buscar agendamento" data-bs-target="#modal_search_schedules" data-bs-toggle="modal">
                          <i class="bi bi-search"></i>
                        </button>
                          <button class="btn btn-flex btn-primary btn-sm py-4 px-4"
                                  title="Liberar horários"
                                  v-if="hasPermission('List Availability') ||
                                hasPermission('List AvailWeek') ||
                                hasPermission('List Availability Block')"
                                  @click.prevent="this.$router.push('/release-times')">
                              <i class="bi bi-unlock-fill"></i>
                          </button>
                          <button class="btn btn-flex btn-success btn-sm py-4 px-4 mx-1" aria-expanded="false"
                                  v-if="hasPermission('Store Schedule')"
                                  title="Novo agendamento" @click="openModal = true">
                              <i class="bi bi-plus-lg"></i>
                          </button>
                          <button class="btn btn-flex btn-danger btn-sm py-4 px-4" aria-expanded="false"
                                  v-if="hasPermission('Cancel Batch Schedule')"
                                  title="Cancelamento em lote" @click="openModalCancel = true">
                              <i class="bi bi-c-circle"></i>
                          </button>
                      </div>
                  </div>
              </div>
          </template>
      </Toolbar>

      <div class="">
        <div class="row mx-0">
          <div :class="showingPatients ? 'col-md-4' : 'col-md-1'" :style="!showingPatients ? 'width: 5%;' : ''">

            <div class="d-flex">
              <ul class="nav nav-pills flex-column">
                <li class="nav-item">
                    <a href="#" class="nav-link text-center mb-2 p-2 border border-primary text-primary" v-if="showingPatients"
                       :class="{'active': activeTab === 'showingPatients'}"
                       @click="activeTab = 'showingPatients'; showingPatients = false; uniqueKey = this.date().unix().toString()">
                        <i class="bi bi-arrow-bar-right"></i>
                    </a>
                    <a href="#" class="nav-link text-center mb-2 p-2 border border-primary text-primary"  v-else
                       :class="{'active': activeTab === 'showingPatients'}"
                       @click="activeTab = 'showingPatients'; showingPatients = true; activeTab = 'calendar';">
                        <i class="bi bi-arrow-bar-left" style="color: #FFFFFF"></i>
                    </a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link text-center mb-2 p-2 border border-primary"
                     :class="{'active': activeTab === 'calendar'}"
                     @click="activeTab = 'calendar'; showingPatients = true;">Agenda do Dia</a>
                </li>
                <li class="nav-item">
                  <a href="#" class="nav-link text-center mb-2 p-2 border border-primary"
                     :class="{'active': activeTab === 'occupationRate'}"
                     @click="activeTab = 'occupationRate'; showingPatients = true;">Taxa de Ocupação</a>
                </li>
                <li class="nav-item" v-if="hasPermission('List Note') && filters.id_prof">
                  <a href="#" class="nav-link text-center mb-2 p-2 border border-primary"
                     :class="{'active': activeTab === 'notes'}"
                     @click="activeTab = 'notes'; showingPatients = true;">Recados</a>
                </li>
              </ul>
              <div class="tab-content w-100">
                <div v-if="activeTab === 'calendar'">
                  <CalendarSchedulesToday :id-prof="filters.id_prof"
                                          :id-spec="filters.id_spec"
                                          :unique-key="uniqueKey"
                                          @onClick="scheduleEdit = $event"
                                          @onArriveSchedule="uniqueKey = this.date().unix().toString()"
                                          @onFinishSchedule="uniqueKey = this.date().unix().toString()"
                                          @onReopenSchedule="uniqueKey = this.date().unix().toString()"
                                          @onStartAttendance="uniqueKey = this.date().unix().toString()"
                                          @onPhotoSaved="uniqueKey = this.date().unix().toString()"
                  />
                </div>
                <div v-if="activeTab === 'occupationRate'">
                  <BusyRate
                      :profId="filters.id_prof"
                      :specId="filters.id_spec"
                      @onSelectDate="calendarGoToDate = $event"
                  />
                </div>
                <div v-if="activeTab === 'notes'">
                  <Notes
                      :profId="filters.id_prof"
                      :specId="filters.id_spec"/>
                </div>
              </div>
            </div>

          </div>
          <div :class="showingPatients ? 'col-md-8' : 'col-md-11' ">
              <div class="card mb-3">
                  <div class="">
                      <div class="px-1 my-4 row">
                          <div class="col-md-3">
                              <CalendarInputStatus v-model="filters.status"/>
                          </div>
                          <div class="col-md-5">
                              <CalendarInputProfessionals
                                  v-model="filters.id_prof"
                                  @change="
                                        professional = $event;
                                        filters.id_spec = null;"
                              />
                          </div>
                          <div class="col-md-4">
                              <CalendarInputSpecialties
                                  v-model="filters.id_spec"
                                  :id-prof="filters.id_prof"
                              />
                          </div>
                      </div>
                  </div>
              </div>
            <div class="card">
              <div class="card-body" style="padding: 2rem 0.85rem;">
                <Calendar :professional="professional"
                          :status="filters.status"
                          :unique-key="uniqueKey"
                          :id-spec="filters.id_spec"
                          :goToDate="calendarGoToDate"
                          @onDateClick="onDateClick"
                          @onEventClick="scheduleEdit = $event"
                />
              </div>
            </div>
          </div>
        </div>
        <ModalSearchSchedule :modal-schedule-is-open="openModal"
          @openModalSchedule="scheduleEdit = $event; openModal = true;"
        />
        <CalendarModalSchedule
            :schedule-edit="scheduleEdit"
            :open-modal="openModal"
            :key="scheduleEdit?.sched_id"
            :showHistory="scheduleEdit != null"
            :clickedDate="clickedDate"
            :currentProf="this.filters.id_prof"
            :currentSpec="this.filters.id_spec"
            @onCloseModal="openModal = false; scheduleEdit = null"
            @onArriveSchedule="uniqueKey = this.date().unix().toString()"
            @onScheduleStore="uniqueKey = this.date().unix().toString()"
            @onScheduleCancel="uniqueKey = this.date().unix().toString()"
            @onConfirmSchedule="uniqueKey = this.date().unix().toString()"
            @onScheduleUpdate="uniqueKey = this.date().unix().toString()"
            @onStartAttendance="uniqueKey = this.date().unix().toString()"
            @onReopenSchedule="uniqueKey = this.date().unix().toString()"
            @onFinishSchedule="uniqueKey = this.date().unix().toString()"
        />
        <ModalCancelSchedules
            :id-prof="filters.id_prof"
            :open-modal="openModalCancel"
            @onCloseModal="openModalCancel = false;"
        />
    </div>

    <ScrollUpDown />
  </div>
</template>

<script>
import {mask} from "vue-the-mask";
import SearchPatient from "../../components/SearchPatient";
import Permission from "../../helpers/permission";
import Calendar from './Calendar';
import CalendarInputStatus from "./Status";
import CalendarInputSpecialties from "./Specialties";
import CalendarInputProfessionals from "./Professionals";
import CalendarSchedulesToday from "./SchedulesToday";
import CalendarModalSchedule from "./ModalSchedule";
import ModalCancelSchedules from "./ModalCancelSchedules.vue";
import BusyRate from "./BusyRate.vue";
import Notes from "./Notes.vue";
import ModalSearchSchedule from "./ModalSearchSchedule.vue";
import Toolbar from "../../components/layouts/auth/Toolbar.vue";
import ScrollUpDown from "../../components/ScrollUpDown.vue";

export default {
  name: "Schedules",
  directives: {
    mask
  },
  components: {
    ModalCancelSchedules,
    CalendarModalSchedule,
    CalendarInputStatus,
    CalendarInputSpecialties,
    CalendarInputProfessionals,
    CalendarSchedulesToday,
    SearchPatient,
    Calendar,
    BusyRate,
    Notes,
    Toolbar,
    ScrollUpDown,
    ModalSearchSchedule,
  },
  data() {
    return {
      showingPatients: true,
      activeTab: 'calendar',
      uniqueKey: null,
      calendarGoToDate: null,
      filters: {
        status: null,
        id_prof: null,
        id_spec: null,
      },
      professional: null,
      openModal: false,
      clickedDate: null,
      openModalCancel: false,
      scheduleEdit: null,
    }
  },
  computed: {
    professionals() {
      return this.$store.state.auth.professionals;
    },
    place() {
      return this.$store.state.auth.place;
    },
    userAuth() {
      return this.$store.state.auth.user;
    },
  },
  mounted() {

  },
  methods: {
    date: moment,
    hasPermission(permission) {
      return Permission.has(permission);
    },
    toggleExpandScheduleToday() {
        this.showingPatients = !this.showingPatients;
    },
    onDateClick(date) {
      this.openModal = true;
      this.clickedDate = date;
    }
  }
}
</script>

<style scoped>
.nav-pills .nav-link {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
}
</style>